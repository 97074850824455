<template>
  <div class="tab-heading-and-action">
    <div>
      <h3>Broadcast</h3>
      <p>Here are the your Broadcast List</p>
    </div> 
    
    
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
      
      <fa
        icon="filter"
        class="filter-btn"
        @click="openFilter"
      />
         
      <div
        v-if="showFilter === true"
        class="filtercard"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="closeFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div
            class="reset-btn pointer"
            @click="resetFilter"
          >
            RESET
          </div>
        </div>
         
        
        <select
          id="BusinessCategory"
          v-model="selectedBusinessCategory"
          name="BusinessCategory"
          @change="selectBusinessCategory"
        >
          <option value="">
            Business Category
          </option>
          <option value="Consumer Services">
            Consumer Services
          </option>
          <option value="Health Care">
            Health Care
          </option>
        </select>
  
        <select
          id="gender"
          v-model="selectedGender"
          name="gender"
        >
          <option value="">
            Gender
          </option>
          <option value="Male">
            Male
          </option>
          <option value="FeMale">
            FeMale
          </option>
        </select>
      </div>
      

      <router-link
        :to="'/create-broadcast/'"
        class="create-broadcast"
      >
        <fa icon="plus" />  Create Broadcast
      </router-link>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.title`]="{ item }">
      <v-tooltip
        v-if="item.title!='' && item.title.length > 8 "
        :text="item.title"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString((item.title),28) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.title }}
      </div>
    </template> 

  







    

    <template #[`item.action`]="{ item }">
      <v-tooltip text="View">
        <template #activator="{ props }">
          <fa 
            v-bind="props"
            icon="eye"
            class="pointer"
            @click="viewMember(item.action)"
          />
        </template>
      </v-tooltip>


      <v-tooltip text="Edit">
        <template #activator="{ props }">
          <router-link :to="'/edit-broadcast/'+item.action">
            <fa
              v-bind="props"
              icon="pen-to-square"
              class="pointer"
            />
          </router-link>
        </template>
      </v-tooltip>
      <!-- <fa
        icon="paper-plane"
        class="pointer"
      /> -->
    </template>
  </v-data-table-server>

  
  <!-- delete dialog -->
  <v-dialog 
    v-model="deleteDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3> <fa icon="trash-can" /> Are you sure?</h3>
        Are you sure you want to delete this speaker? This action 
        cannot be undone.
      </v-card-text>
     
      <v-card-actions class="delete-action">
        <button @click="deleteDialog = false">
          Cancel
        </button>
        <button @click="deleteSpeaker">
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete dialog -->

    
      
  <div
    v-if="viewDialog"
    class="view-dialog-overlay"
    @click.self="viewDialog = false"
  >
    <div class="view-member-modal drawer-active">
      <div class="view-modal-action">
        <fa
          icon="arrow-right-long"
          @click="viewDialog = false"
        />
        <h3>{{ truncatedString(broadcastView.title) }}</h3>
      </div>

      <div class="view-member-details">
        <div class="member-details-content">
          <h5>Title</h5>
          <p>{{ broadcastView.title }}</p>
          <h5>Message</h5>
          <MarkdownRenderer :source="broadcastView.message" />
          <br>
          <h5>Gallery</h5>
          <p>{{ broadcastView.albumName }}</p>
          <h5>Attachments</h5>
          <div
            v-if="broadcastView.attachments.length > 0"
            class="attachments-container"
          >
            <div
            
              v-for="item in broadcastView.attachments"
              :key="item"
            >
              <!-- {{ getFileFormatFromBase64(item.source) }} -->
              <v-tooltip
                v-if="item.fileName!='' && item.fileName.length > 8 "
                :text="item.fileName.split('/').pop().substring(36)"
              >
                <template #activator="{ props }">
                  <div
                    target="_blank"
                    href="#"
                    v-bind="props"
                  >
                    <img
                      v-if="getFileExtension(item.fileName)=='jpg' || getFileExtension(item.fileName)=='png'"
                      :src="'data:image/jpeg;base64,'+item.source"
                      class="attachments-thumbnail"
                    >
                  </div>
                </template>
              </v-tooltip>
              <v-tooltip
                v-if="item.fileName!='' && item.fileName.length > 8 "
                :text="item.fileName?.split('/').pop().substring(36)"
              >
                <template #activator="{ props }">
                  <div
                    v-if="getFileExtension(item.fileName)=='pdf' || getFileExtension(item.fileName)=='docx'"
                    target="_blank"
                    href="#"
                    v-bind="props"
                    class="attachments-thumbnail"
                  />
                </template>
              </v-tooltip>
            </div>
          </div>
          <div v-else>
            <p>N/A</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>





<script setup>
// import MarkdownRenderer from '@/components/ui/MarkdownRenderer.vue'
import {ref,reactive,watch,provide,inject,onBeforeMount,computed} from 'vue'
import { useStore,mapMutations } from 'vuex';
import axios from 'axios';

import { truncateString} from '@/helper/index.js';


const store = useStore(); 





// const markdown = ref('<h2> hello word </h2>')

// const markdownToHtml = computed(() => {
// 	return marked(markdown.value)
// });


const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'Title', value: 'title' },
	{ title: 'Recipients', value: 'recipients' },
	{ title: 'Schedule Date', value: 'scheduleDate' },
	{ title: 'Schedule Time', value: 'scheduleTime' },
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const speakerId=ref('');
const mode =  ref('add');
const broadcastView = reactive({
	title:'',
	message:'',
	albumName:'',
	attachments:[]
})

const name= ref('');
const	search= ref('');
const	snackbar=ref(false);
const viewDialog=ref(false);
const deleteDialog=ref(false);
const	text= ref('');
const	timeout= ref(2000);
const tableHeight = ref(0);
const showFilter = ref(false);

const openFilter = () =>{
	showFilter.value = true;
}

const closeFilter = () =>{
	showFilter.value = false;
}
          
const resetFilter = () =>{
	// selectedBusinessCategory.value = '';
	// selectedGender.value = '';
}


onBeforeMount(() => {
	  if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
})

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});








// watchEffect(tableRefresh.value,(val) => {
//       console.log('new returnTableRefresh', val.value)
//     })







const loadItems  = ({ page, itemsPerPage, sortBy })=> {
	offset.value = (page - 1) * itemsPerPage
	console.log("offset",offset.value);
	loading.value = false;
	serverItems.value = [
		{
			title: "New Year Update",
			recipients: "All Subscribers",
			scheduleDate: "2025-01-01",
			scheduleTime: "10:00 AM",
      action:1
		},
		{
			title: "Product Launch",
			recipients: "VIP Customers",
			scheduleDate: "2025-02-15",
			scheduleTime: "03:00 PM",
      action:2
		},
		{
			title: "Monthly Newsletter",
			recipients: "General Subscribers",
			scheduleDate: "2025-03-01",
			scheduleTime: "09:00 AM",
      action:3
		},
		{
			title: "Holiday Discounts",
			recipients: "Promo Subscribers",
			scheduleDate: "2025-12-20",
			scheduleTime: "08:00 AM",
      action:4
		},
		{
			title: "Feedback Request",
			recipients: "Recent Purchasers",
			scheduleDate: "2025-04-10",
			scheduleTime: "01:00 PM",
      action:5
		}
	];
totalItems.value = 5;

	// axios
	// 	.get(`${process.env.VUE_APP_SERVICE_URL}/notification?limit=${itemsPerPage}&offset=${offset.value}`,{
	// 		params:{
	// 			value:name.value
	// 		}, 
	// 		headers: {
	// 			'Authorization': `Bearer ${store.state.authentication.token}`,
	// 		}})
	// 	.then((response) => {
	// 		serverItems.value = []
	// 		console.log('response.data.title',response.data);
	// 		// serverItems = response.data.data;
	// 		totalItems.value = response.data.totalCount;
	// 		loading.value = false
          
	// 		let broadcastData = response.data.data;
      
	// 		broadcastData.forEach((item,index) => {
	// 			let fileNames = item?.files.map(str => {return str?.split("/").pop().substring(36)});
	// 			console.log('item',item)
	// 			console.log('fileNames', fileNames)
	// 			serverItems.value.push({title: item?.title || '',
	// 				message:  item?.content,
	// 				attachments: fileNames?fileNames.join(', '):'' ,
	// 				date:formattedDate(item?.createdAt),
	// 				action:item._id})
	// 		});


	// 	})
	// 	.catch((error)=>{
	// 		if (error.response) {
	// 			if(error.response.data.message){
	// 				if(error.response.data.message ==='jwt expired'){
	// 					store.commit('snackbar/showSnackbar',{
	// 						show:true,
	// 						message:'Token expired',
	// 						color:'danger',
	// 						timeout:2000});
	// 					store.commit('authentication/logout',2000);
	// 				}
	// 				store.commit('snackbar/showSnackbar',{
	// 					show:true,
	// 					message:error.response.data.message,
	// 					color:'danger',
	// 					timeout:2000});

	// 			}else{
  
	// 				store.commit('snackbar/showSnackbar',{
	// 					show:true,
	// 					message:error.response.statusText,
	// 					color:'danger',
	// 					timeout:2000});
	// 			}
	// 		}else {
	// 			console.log("error.message",error.message)
	// 			store.commit('snackbar/showSnackbar',{
	// 				show:true,
	// 				message:error.message,
	// 				color:'danger',
	// 				timeout:2000});
	// 		}
	// 	});

        
}



const	deleteSpeakerConfirmation = (item) =>{
	speakerId.value = item;
	deleteDialog.value=true;
}

const deleteSpeaker = ()=>{
	axios
		.delete(`${process.env.VUE_APP_SERVICE_URL}/speaker/${speakerId.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				deleteDialog.value=false;
				viewDialog.value=false;
				search.value = String(Date.now());
				snackbar.value=true;
				text.value = "Deleted Successfully"; 
						
			}
		})
}

	

const viewMember = (id)=>{
	broadcastView.attachments = [];
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/notification/${id}`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			viewDialog.value=true;
			console.log("response",response);
			let getMemberDetails = response.data.data[0];
			broadcastView.title = `${getMemberDetails.title?getMemberDetails.title :''} ${getMemberDetails.firstName?getMemberDetails.firstName :''} ${getMemberDetails.lastName?getMemberDetails.lastName :''}`;
			broadcastView.message = getMemberDetails?.content || 'N/A';

			if(getMemberDetails?.files.length > 0){
				getMemberDetails?.files.forEach((item)=>{
					console.log('attac',item)
					loadImage(item.split('/profile/')[1])
				})
			}

		
			broadcastView.albumName = getMemberDetails?.albumName || 'N/A';
					
		})
}






const messageFromChild = ref('');

const receiveMessageFromChild = (message) => {
	// Receive the message from the child component
	messageFromChild.value = message;
	console.log("message",message);
};

const truncatedString = (string) =>{
	let result = string.substring(0, 10);
	// Add dots if the original string length is longer than 8 characters
	if (string.length > 8) {
		result += "..";
	}
	return result;
}

const loadImage = (fileName)=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/files?name=${fileName}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
					
		      
				   broadcastView.attachments.push({source:response?.data?.data,options:{type:'local'},fileName:fileName});
				console.log("FILE",broadcastView.attachments);
			}
		})
		.catch((error)=>{
			if (error.response) {
				if(error.response.data.message){
					if(error.response.data.message ==='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
						store.commit('authentication/logout',2000);
					}
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.data.message,
						color:'danger',
						timeout:2000});

				}else{

					store.commit('snackbar/showSnackbar',{
						show:true,
						message:error.response.statusText,
						color:'danger',
						timeout:2000});
				}
			}else {
				console.log("error.message",error.message)
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.message,
					color:'danger',
					timeout:2000});
			}
		});
  
}

function getFileFormatFromBase64(base64String) {
	// Extract the first few characters from the base64 string
	const firstFewChars = base64String.substring(0, 20); // Adjust the length as needed

	// Define mappings of file format signatures to MIME types
	const signatures = {
		'/9j/': 'image/jpeg',
		'iVBORw0KGgo=': 'image/png',
		'JVBERi0': 'application/pdf', // PDF signature
		'UEsDBBQACAAIAAAAAA': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX signature
		'UEsDBBQABgAIAAAAIQ': 'application/msword'
	};

	// Check if any of the signatures match
	for (const signature in signatures) {
		if (firstFewChars.startsWith(signature)) {
			return signatures[signature];
		}
	}

	// If no matching signature is found, return a default MIME type or null
	return null;
}

function getFileExtension(filename) {
	// Find the last occurrence of the period (.)
	const lastDotIndex = filename.lastIndexOf('.');
	// If a period is found and it's not the last character, extract the substring after it
	if (lastDotIndex !== -1 && lastDotIndex < filename.length - 1) {
		return filename.substring(lastDotIndex + 1);
	}
	// If no period is found or it's the last character, return an empty string
	return '';
}


</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 18px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
.filter-btn{position: relative;cursor: pointer;}
  .filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
  .filtercard select{border: 1px solid #B2B2B2;
      padding: 6px;
      margin-bottom: 12px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:#B2B2B2;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  :deep() .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
  .filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
  .reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;}
  .filter-close-btn{width: max-content;
      margin-left: auto;
      display: block;
     cursor: pointer;}
.search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
.search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
.search-container input{outline: unset;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .create-broadcast{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;
   text-decoration: none;}
.create-broadcast .fa-plus{margin-right: 8px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th{width: 120px;}
.v-table :deep() thead tr > th:nth-child(2){width:300px;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;color:#007CBA}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-paper-plane{color: #007CBA;}

.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
  .view-member-details::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .drawer-active{
      animation: fade-in 500ms forwards;
	}

 
	
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
  .attachments-container{width: 100%;
    display: flex;
    flex-wrap: wrap;
    }
  .attachments-thumbnail,a.attachments-thumbnail{display: block; width: 80px;height: 80px;margin-right: 12px; object-fit: cover;background-color: gainsboro;border-radius: 5px;margin-bottom: 12px;}

  @media only screen and (max-width: 600px){
		.tab-heading-and-action{flex-direction: column;}
		.filter{margin-left: unset;flex-direction: column;align-items: unset;}
    .create-broadcast{margin-top: 12px;margin-left: unset;}
	}
</style>






