import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import LoginView from '../views/LoginView.vue'
import MembersView from '../views/MembersView.vue'
import MembersOldView from '../views/MembersOldView.vue'
import AddMembersView from '../views/AddMembersView.vue'
import EventView from '../views/EventView.vue'
import BroadcastView from '../views/BroadcastView.vue'
import SpeakersView from '../views/SpeakersView.vue'
import ScheduleView from '../views/ScheduleView.vue'
import CalendarView from '../views/CalendarView.vue'
import VenueView from '@/views/VenueView.vue'
import EventSchedulerView from '../views/EventSchedulerView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import TermsAndConditionView from '../views/TermsAndConditionView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import CreateBroadcastView from '../views/CreateBroadcastView.vue'
import MasterChapterView from '../views/MasterChapterView.vue'
import TestView from '../views/TestView.vue'



const routes = [
	{
		path: '/',
		name: 'login',
		component: LoginView,
		meta:{
			title:'CCS ConfApp -  Login',
			auth:false
		}
	},
	{
		path: '/members',
		name: 'members',
		component: MembersView,
		meta:{
			title:'CCS ConfApp -  Member',
			auth:true
		}
	},
	{
		path: '/add-members',
		name: 'add-members',
		component:AddMembersView,
		meta:{
			title:'CCS ConfApp -  Add Member',
			auth:true
		}
	},
	{
		path: '/event',
		name: 'event',
		component:EventView,
		meta:{
			title:'CCS ConfApp -  Event',
			auth:true
		}
	},
	{
		path: '/broadcast',
		name: 'broadcast',
		component:BroadcastView,
		meta:{
			title:'CCS ConfApp -  Broadcast',
			auth:true
		}
	},
	{
		path: '/scheduler/:id',
		name: 'scheduler',
		component:EventSchedulerView,
		meta:{
			title:'CCS ConfApp -  Scheduler',
			auth:true
		}
	},
	{
		path: '/speakers',
		name: 'speakers',
		component:SpeakersView,
		meta:{
			title:'CCS ConfApp -  Speaker',
			auth:true
		}
	},
	{
		path: '/schedule/:id',
		name: 'schedule',
		component:ScheduleView,
		meta:{
			title:'CCS ConfApp -  Schedule',
			auth:true
		}
	},
	{
		path: '/calendar',
		name: 'calendar',
		component:CalendarView,
		meta:{
			title:'CCS ConfApp -  Schedule',
			auth:true
		}
	},
	{
		path: '/venue',
		name: 'venue',
		component:VenueView,
		meta:{
			title:'CCS ConfApp -  Venue',
			auth:true
		}
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: PrivacyPolicyView,
		meta:{
			title:'CCS ConfApp -  Privacy Policy',
			auth:false
		}
	},
	{
		path: '/terms-and-condition',
		name: 'terms-and-condition',
		component: TermsAndConditionView,
		meta:{
			title:'CCS ConfApp -  Terms and Conditions',
			auth:false
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found/',
		
		component: NotFoundView,
		meta:{
			title:'CCS ConfApp -  Not Found',
			auth:false
		}
	},
	{
		path: '/members-test',
		name: 'members-test',
		component:MembersOldView,
		meta:{
			title:'CCS ConfApp -  Venue',
		}
	},
	{
		path: '/create-broadcast',
		name: 'CreateBroadcast',
		component:CreateBroadcastView,
		meta:{
			title:'CCS ConfApp -  Createbroadcast',
			auth:true
		}
	  },
	  {
		path:'/master-chapter',
		name: 'MasterChapter',
		component: MasterChapterView,
		meta:{
			title:'CCS ConfApp -  Master Chapter',
			auth:true
		}
	  },
	  {
		path:'/test',
		name: 'Test',
		component: TestView,
		meta:{
			title:'CCS ConfApp -  test',
		}
	  }

	
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})


router.beforeEach((to, from, next)=>{
	console.log('to',to);
	console.log('from',from);
	//    console.log('next',next());
	
	if('auth' in to.meta && to.meta.auth && !store.state.authentication.token){
		document.title = `${to.meta.title}`;
		next('/');
	}else if('auth' in to.meta && !to.meta.auth && store.state.authentication.token){
		document.title = `${to.meta.title}`;
		next('/members');
	}else{
		document.title = `${to.meta.title}`;
		next();
	}
})

export default router
