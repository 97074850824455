<template>
  <div
    :id="'event-option-dropdown-'+event.id+'-'+trackId"
    class="event-option-dropdown"
  >
    <div
      class="close-icon pointer"
      :data-eCloseId="event.id+'-'+trackId"
      @click="closeEventOption"
    >
      &#128473;
    </div>
    <div
      class="menu-item pointer edit-event"
      :data-e-edit-id="event.id+'-'+trackId"
      :data-event-name="event.title"
      :data-start-time="event.start"
      @click="editEvent"
    >
      <fa 
        icon="pen-to-square"
      />Edit
    </div>
    <div
      class="menu-item pointer delete-event" 
      :data-eDeleteId="event.id+'-'+trackId"
      @click="deleteEventConfirmationDialog"
    >
      <fa 
        icon="trash"
      />Delete
    </div>
  </div>
</template>

<script setup>
import { defineProps, inject } from 'vue';
const hideTrackOption = inject('hideTrackOption');
const editTrack = inject('editTrack');
const disableEventOption = inject('disableEventOption');
const deleteEventConfirmation = inject('deleteEventConfirmation');
const editEventForm = inject('editEventForm');

defineProps({
	event: {
		type: Object,
		required: true,
		default: () => ({})
	},
  trackId: {
		type: String,
		required: true
	},
});

const closeEventOption = (event)=>{
	disableEventOption(event);
}

const editEvent = (event)=>{
	editEventForm(event);
}

const deleteEventConfirmationDialog = (event)=>{
	deleteEventConfirmation(event);
}


</script>

<style scoped>
.close-icon{ 
    width: 18px;
    color: gray;
    position: absolute;
    right: 6px;
    top: 2px;
    font-size: 14px;
	}	
.event-option-dropdown{
    display:none;
    position: absolute;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 16px 10px 10px;
    border-radius: 9px;
    top:0;
    right: 0;
    z-index: 1040;
}
.event-option-dropdown{width: 100px;right:0px;}	
.menu-item{font-size: 14px;text-align:left;}
.menu-item .fa-pen-to-square, .menu-item .fa-trash{margin-right: 9px;}
.menu-item .fa-trash{color:red;}	
.disable-option,.disable-event-option{position: absolute;
    right: 7px;
    top: 8px;
    color: gray;}
</style>