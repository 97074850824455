<template>
  <div
    class="topic-card"
  >
    <div class="actions">
      <div v-if="item.title.length > 15">
        <v-tooltip
          :text="item.title"
          location="top"
        >
          <template #activator="{ props }">
            <h3 v-bind="props">
              {{ truncateString(item.title, 15) }}
            </h3>
          </template>
        </v-tooltip>
      </div>
      <div v-else>
        <h3>{{ item.title }}</h3>
      </div> 
      <div>
        <fa
          icon="pencil"
          class="pointer"
          @click="editTopic"
        />
        <fa
          icon="trash"
          class="pointer"
          @click="deleteTopic"
        />
      </div>
    </div>
    <div class="topic-duration">
      {{ formatDuration(item.duration) }}
    </div>
  </div>
</template>

<script setup>
import { formatDuration, truncateString } from '@/helper/index.js';
import { useStore} from 'vuex';

const store = useStore(); 
const emit = defineEmits(['deleteTopic','editTopic']);
const props = defineProps({
	item:{
		type:Object,
		required:true,
		default:() => ({})
	}
})

const deleteTopic = ()=>{
	emit('deleteTopic');
}

const editTopic = ()=>{
	emit('editTopic');
}





</script>

<style scoped>
.topic-card{box-shadow: 0px 5px 10px 0px #93939340;
padding: 12px;
margin: 10px;
    border-radius: 12px;} 
.topic-card h3{font-size: 16px;}
.topic-card .actions{display: flex;
    align-items: center;
    justify-content: space-between;}
 .topic-duration{font-size: 14px;
    margin-top: 6px;
    
}
.fa-pencil{margin-right: 5px;cursor: pointer;}
.fa-trash{color:#EC1B1B;cursor: pointer;}
.speakers{margin-top: 10px;}
.speakers img{    border-radius: 50%;
    margin-right: 12px;
    width: 46px;
    height: 46px;
    object-fit: cover;}
</style>

