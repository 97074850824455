<template>
  <Form
    ref="form"
    :initial-values="initialData"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <table>
      <thead>
        <tr>
          <th>First Name<span>*</span></th>
          <th>Middle Name</th>
          <th>Last Name</th>
          <th>Gender</th>
          <th>Phone Number<span>*</span></th>
          <th>Email</th>
          <th>Chapter<span>*</span></th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <FieldArray
          v-slot="{ fields, push, remove }"
          name="members"
        >
          <tr
            v-for="(field, idx) in fields"
            :key="field.key"
          >
            <td>
              <Field
                :id="`firstName_${idx}`"
                type="text"
                :name="`members[${idx}].firstName`"
                placeholder="First Name"
              />
              <ErrorMessage :name="`members[${idx}].firstName`" />
            </td>
            <td>
              <Field
                :id="`middleName_${idx}`"
                type="text"
                :name="`members[${idx}].middleName`"
                placeholder="Middle Name"
              />
              <ErrorMessage :name="`members[${idx}].middleName`" />
            </td>
            <td>
              <!-- <input type="text" v-model="field.value.lastName"> -->
              <Field
                :id="`lastName_${idx}`"
                type="text"
                :name="`members[${idx}].lastName`"
                placeholder="Last Name"
              />
              <ErrorMessage :name="`members[${idx}].lastName`" />
            </td>
            <td>
              <Field
                v-slot="{ field }"
              
                type="text"
                :name="`members[${idx}].gender`"
              >
                <select
                  :id="`gender_${idx}`"
                  v-bind="field"
                >
                  <option
                    value=""
                    selected 
                    hidden
                    disabled
                  >
                    Gender
                  </option>
                  <option value="Male">
                    Male
                  </option>
                  <option value="Female">
                    Female
                  </option>
                  <option value="Other">
                    Other
                  </option>
                </select>
              </Field>
      
              <ErrorMessage :name="`members[${idx}].gender`" />
            </td>
            <td>
              <Field
                :id="`phoneNumber_${idx}`"
                :name="`members[${idx}].phoneNumber`"
              >
                <vue-tel-input
                  v-model="field.value.phoneNumber"
                  :input-options="options"
                />
              </Field>
              <ErrorMessage :name="`members[${idx}].phoneNumber`" />
            </td>
            <td>
              <Field
                :id="`email_${idx}`"
                type="text"
                :name="`members[${idx}].email`"
                placeholder="Email"
              />
              <ErrorMessage :name="`members[${idx}].email`" />
            </td>
            <td>
              <Field
                :id="`chapter_${idx}`"
                type="text"
                :name="`members[${idx}].chapter`"
                placeholder="Chapter"
              />
              <ErrorMessage :name="`members[${idx}].chapter`" />
            </td>
            <td class="row-actions">
              <fa
                icon="circle-plus"
                class="pointer"
                @click="push({'firstName': '',
                              'middleName':'',
                              'lastName': '',
                              'gender':'',
                              'phoneNumber': '',
                              'email': '',
                              'chapter':''})"
              /> <fa
                v-if="idx==0"
                icon="trash"
                class="pointer"
                style="visibility:hidden"
                @click="remove(idx)"
              />
              <fa
                v-else
                icon="trash"
                class="pointer"
                @click="remove(idx)"
              />
            </td>
          </tr>
        </FieldArray>
      </tbody>
    </table>
    <div class="form-actions">
      <button
        type="button"
        @click="resetForm"
      >
        Reset
      </button>
      <button
        type="submit"
        :disabled="isDisabled"
      >
        Save
        <fa
          v-show="spinner===true"
          icon="spinner"
          spin
        />
      </button>
    </div>
  </Form>

  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>





<script setup>
import { isValidPhoneNumber } from "libphonenumber-js/mobile"; 
import {ref} from 'vue';
import {Form, Field, ErrorMessage, FieldArray } from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import { useStore } from 'vuex';

const store = useStore(); 
const form  = ref();
const dialCode = ref('');
const	timeout= ref(2000);
const spinner = ref(false);
const isDisabled = ref(false);

// const chapter = ref('');
const snackbar= ref(false);
const text= ref('');
const options = ref({ placeholder: "Phone Number" })
// let isValid = ref(false);
const initialData = {
	members: [{'firstName': '',
		'middleName':'',
		'lastName': '',
		'gender':'',
		'phoneNumber': '',
		'email': '',
		'chapter':''}]
};

const schema = yup.object().shape({
	members: yup
		.array()
		.of(
			yup.object().shape({
				firstName: yup.string().required('Please enter firstname')
					.matches(/^[A-Za-z]+$/, 'Only letters are allowed'),
				middleName: yup.string().matches(/^[A-Za-z]*$/, 'Only letters are allowed').label("middleName"),
				lastName: yup.string().matches(/^[A-Za-z]*$/, 'Only letters are allowed').label("lastName"),
				phoneNumber:yup.string().required('Please enter phone number').label("phoneNumber").test('custom-validation', 'Invalid phone number', function(value) {
					console.log('value',value,isValidPhoneNumber(value));
					if (!isValidPhoneNumber(value)) {
						return false; // Validation failed
					}
					return true;
				}),
				email: yup.string().email('Invalid email address').label("email"),
				chapter: yup.string().required('Please enter chapter').matches(/^[A-Za-z]+$/, 'Only letters are allowed').label("chapter")
			})
		)
		.strict(),
});

  



const onSubmit = (data) => {

	isDisabled.value = true;
	spinner.value = true;
	const membersData = data.members.map(({ firstName, middleName, lastName, gender, phoneNumber, email, chapter }) => ({
  
		"first": firstName,
		"middle":middleName,
		"last": lastName,
		"email": email,
		"phone": {
			"dialCode": '+91',
			"number":phoneNumber.replace(phoneNumber.split(' ')[0],'').replace(/\D/g, '')
		},
		"gender":gender,
		"state":chapter  

	}));


	console.log("membersData",membersData);
	axios
		.post(`${process.env.VUE_APP_SERVICE_URL}/member`,
			membersData,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Created Successfully',
					color:'success',
					timeout:2000});
				form.value.resetForm();
			}
		})
		.catch((error) => {
			console.log('error',error);
			if(error.response.status === 400){
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Phone Number already exists',
					color:'danger',
					timeout:2000});
			}
		}).finally(() => {
			isDisabled.value = false;
			spinner.value = false;
		});

    
};



const resetForm = ()=>{
	form.value.resetForm()  
}
 

  

 


    

</script>

<style scoped>
table {
  width: 100%;
  border-radius: 40px!important;
  border-spacing: 0;
  border-collapse: separate;
  border: unset;
}

table  td, th {
  border: 1px solid #dddddd;
  padding: 8px;
}
thead > tr > th{font-weight: 500;text-align: left;padding: 12px 9px;border-bottom:unset;border-right: unset;}
thead > tr > th:nth-child(1){border-radius: 12px 0 0 0;}
thead > tr > th:last-child{border-radius:  0 12px 0 0;border-right: 1px solid #dddddd; }
thead > tr > th:nth-child(4){width: 90px;}
thead > tr > th span{color:red;}


tbody > tr > td{padding:0}
tbody > tr:not(:last-child) td{border-bottom: unset;border-right: unset;}
tbody > tr:not(:last-child) td:last-child{border-right: 1px solid #dddddd;}
tbody > tr > td input,tbody > tr > td select{height:43px;width: 100%;padding: 12px 9px;}
tbody > tr > td input:focus,tbody > tr > td select:focus{outline: unset;}
tbody > tr > td span,tbody > tr > td .error-msg{color: #FF0000;font-size: 12px;text-align: left;margin-left: 11px;}
tbody > tr > td .fa-trash{color:#FF0000;text-align: center;cursor: pointer;}
tbody > tr > td .fa-circle-plus{color:#8BC34A;cursor: pointer;}
.vue-tel-input{border:unset!important;}
.vue-tel-input:focus-within{
-webkit-box-shadow: none!important;
  box-shadow: none!important;}
 

/* curve */
tbody > tr:last-child td:nth-child(1){border-radius:  0 0 0 12px;}
tbody > tr:last-child td:last-child{border-radius:  0 0 12px  0;border-right: 1px solid #dddddd;text-align: center;}
.row-actions{text-align: center;}
/* curve */
tbody > tr:last-child td{border-bottom: 1px solid #dddddd;border-right: unset;}

#addMember{border-radius: 5px;
    background: #007CBA;
    color: #fff;
    padding: 8px;
    margin-left: auto;
    margin-top: 12px;
    display: block;}

.form-actions{display: flex;justify-content: flex-end;margin-top: 22px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
    select{
    cursor: pointer;
    outline: unset;
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 105% 60%;
    background-repeat: no-repeat;
    background-size: auto 13%;
}    
</style>

