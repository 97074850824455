<template>
  <div class="tab-heading-and-action"> 
    <div class="heading">
      <h3>{{ props.type }}</h3>
    </div>
    

    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>

      <fa
        icon="filter"
        class="filter-btn"
        @click="openFilter"
      />

      <div
        v-if="showFilter === true"
        class="filtercard"
      >
        <fa
          icon="close"
          class="filter-close-btn"
          @click="closeFilter"
        />
        <div class="filter-action">
          <h3>Filter</h3>
          <div
            class="reset-btn pointer"
            @click="resetFilter"
          >
            RESET
          </div>
        </div>
       

        <div class="input-group">
          <flat-pickr
            v-model="selectedDate"
            :config="dateConfig"
            class="date-form-control"
            placeholder="Date"
            name="selectedDate"
          />
          <div class="input-group-append">
            <button
              type="button"
              colorpicker="colorpickere"
              data-toggle
            >
              <fa icon="calendar" />
              <span
                aria-hidden="true"
                class="sr-only"
              >Toggle</span>
            </button>
          </div>
        </div>

        <select
          id="status"
          v-model="selectedStatus"
          name="status"
        >
          <option value="">
            Status
          </option>
          <option value="upcoming">
            Upcoming
          </option>
          <option value="ongoing">
            Ongoing
          </option>
        </select>
      </div>
    
   
      <div
        class="add-event"
        @click="clickDialog"
      >
        <fa icon="plus" /> Add Event
      </div>
    </div>
  </div>
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.eventName`]="{ item }">
      <v-tooltip
        v-if="item.eventName!='' && item.eventName.length > 8 "
        :text="item.eventName"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(item.eventName,10) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.eventName }}
      </div>
    </template> 

    <template #[`item.noOfRegisteredMembers`]="{ item }">
      <div class="gray-box">
        {{ item.noOfRegisteredMembers }}
      </div>
    </template>

    <template #[`item.noOfVisitors`]="{ item }">
      <div class="gray-box">
        {{ item.noOfVisitors }}
      </div>
    </template>

    <template #[`item.status`]="{ item }">
      <div :class="item.status">
        {{ item.status }}
      </div>
    </template>
  
    <template #[`item.action`]="{ item }">
      <!-- <router-link :to="'/schedule/'+item.action"> -->
      <router-link :to="'/scheduler/'+item.action">
        <fa
          icon="edit"
        />
      </router-link>
      <fa
        icon="pencil"
        class="pointer"
        @click="editEvent(item.view)"
      />
      <fa
        icon="trash"
        class="pointer"
        @click="deleteEventConfirmation(item.action)"
      />
    </template>
  </v-data-table-server>
  

 
  <div class="text-center">
    <!-- <v-btn
      color="primary"
      @click="deleteDialog = true"
    >
      Open Dialog
    </v-btn> -->

    <v-dialog 
      v-model="deleteDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="deleteDialog = false"
        >
          <fa icon="close" />
        </div>
        
          
        <v-card-text>
          <h3> <fa icon="trash-can" /> Are you sure?</h3>
          Are you sure you want to delete this Event? This action 
          cannot be undone.
        </v-card-text>
     
        <v-card-actions class="delete-action">
          <button @click="deleteDialog = false">
            Cancel
          </button>
          <button @click="deleteEvent">
            Delete
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>





<script setup>
import {ref,watch,onBeforeMount,provide} from 'vue'
import {getEventList, getEventCount} from '@/service/helper-service.js';
import { truncateString } from '@/helper/index.js';
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from 'axios';
import { useStore } from 'vuex';
const store = useStore(); 
const props = defineProps({
	type:{
		type:String,
		default:''
	}
})








const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'Event Name', value: 'eventName' },
	{ title: 'Start Date', value: 'startDate' },
	{ title: 'End Date', value: 'endDate' },
	// { title: 'Venue', value: 'venue' },
	{ title: 'No of Registered Members', value: 'noOfRegisteredMembers' },
	{ title: 'No. of Visitors', value: 'noOfVisitors' },
	{ title: 'Status', value: 'status' },
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const eventId=ref('');


const name= ref('');
const	search= ref('');
const	eventStatus= ref('');
const selectedStatus = ref('');
const selectedDate = ref('');
const	eventDate = ref('');
const	snackbar=ref(false);
const viewDialog=ref(false);
const deleteDialog=ref(false);
const	text= ref('');
const	timeout= ref(2000);
const tableHeight = ref(0);
const showFilter = ref(false);
const mode = ref('create');
const eventData = ref('eventData')
provide('eventData',eventData);
provide('mode',mode);

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});





watch(eventStatus, () => {
	search.value = String(Date.now())
})


watch(selectedStatus, (newValue) => {
	eventStatus.value = newValue;
})

watch(eventDate, () => {
	search.value = String(Date.now())
})

watch(selectedDate, (newValue) => {
	eventDate.value = newValue;
})

watch(() => store.state.event.isEventUpdated, (newValue) => {
	if(newValue==true){
		search.value = String(Date.now())
    store.state.event.isEventUpdated = false;
	}

});



onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
})

const openFilter = ()=>{
	showFilter.value = true;
}

const	closeFilter = ()=>{
	showFilter.value = false;
}

const	resetFilter= ()=>{
	selectedDate.value ='';
	selectedStatus.value ='';
}

const clickDialog = () => {
	store.state.event.showAddEventDialog = true;
}

const loadEventsCount = async()=>{
	try {
  
		const response = await getEventCount(`/eventCount`);

		let eventCounts = response?.data?.data; 
		store.state.event.activeEvent = eventCounts?.activeEvents;
		store.state.event.eventHistory = eventCounts?.completedEvents; 
		store.state.event.allEvent = eventCounts?.allEvents;
	} catch (error) {
		console.log(error);
	}
}

const dateConfig = ref({
	wrap: true, // set wrap to true only when using 'input-group'
	altInput: true,
	altFormat: "j-M-Y",
	dateFormat: "Y-m-d",
	altInput: true,
	disableMobile: true,    
});




// const loadItems  = ({ page, itemsPerPage, sortBy })=> {
// 	offset.value = (page - 1) * itemsPerPage
// 	console.log("offset",offset.value);
// 	loading.value = true
      
// 	axios
// 		.get(`${process.env.VUE_APP_SERVICE_URL}/event?limit=${itemsPerPage}&offset=${offset.value}`,{
// 			params:{
// 				value:name.value,
// 				eventStatus:eventStatus.value,
// 				date:eventDate.value
// 			}, 
// 			headers: {
// 				'Authorization': `Bearer ${store.state.authentication.token}`,
// 			}})
// 		.then((response) => {
// 			serverItems.value = []
// 			console.log('response.data.title',response.data);
// 			// serverItems = response.data.data;
// 			totalItems.value = response.data.totalCount;
// 			loading.value = false
          
// 			let memberData = response.data.data;
// 			memberData.forEach((item,index) => {
// 				// let fullName =  `${item.title?item.title :''} ${item.firstName?item.firstName :''} ${item.lastName?item.lastName :''}`;
// 				serverItems.value.push({
// 					eventName: item.eventName?item.eventName:'-',
// 					startDate: item.days?formattedDate(item?.days?.[0]?.date):'-',
// 					endDate: item.days?formattedDate(item?.days?.[item?.days?.length - 1]?.date):'-',
// 					time:`${item.startTime?formattedTime(item.startTime):'-'} to ${item.endTime?formattedTime(item.endTime):'-'}`,
// 					venue:item.venue,
// 					noOfRegisteredMembers' },:item.registeredMemberCount?item.registeredMemberCount:'0',
// 					noOfVisitors:item.visitorCount?item.visitorCount:'0',
// 					status:item.status?capitalizedStr(item.status):'-',
// 					action:item._id?item._id:'-',
// 				})

// 			});
// 			store.state.event.activeEvent = response?.data?.totalCount;


// 		})
//     .catch((error)=>{
// 			if (error.response) {
// 				if(error.response.data.message){
// 					if(error.response.data.message ==='jwt expired'){
// 						store.commit('snackbar/showSnackbar',{
// 							show:true,
// 							message:'Token expired',
// 							color:'danger',
// 							timeout:2000});
// 						store.commit('authentication/logout',2000);
// 					}
// 					store.commit('snackbar/showSnackbar',{
// 						show:true,
// 						message:error.response.data.message,
// 						color:'danger',
// 						timeout:2000});

// 				}else{
  
// 					store.commit('snackbar/showSnackbar',{
// 						show:true,
// 						message:error.response.statusText,
// 						color:'danger',
// 						timeout:2000});
// 				}
// 			}else {
// 				console.log("error.message",error.message)
// 				store.commit('snackbar/showSnackbar',{
// 					show:true,
// 					message:error.message,
// 					color:'danger',
// 					timeout:2000});
// 			}
// 		})

        
// }

const loadItems = async({ page, itemsPerPage, sortBy })=>{

	offset.value = (page - 1) * itemsPerPage
	loading.value = true

	try {
		const response = await getEventList(`/event?${eventStatusQuery(props.type)}&limit=${itemsPerPage}&offset=${offset.value}`,{
			value:name.value,
			eventStatus:eventStatus.value,
			date:eventDate.value});

		console.log('response.data',response.data);
   
		totalItems.value = response?.data?.totalCount;
		loading.value = false;

		let memberData = response?.data?.data;
		serverItems.value =  memberData.map(item => ({
			eventName: item.eventName?item.eventName:'-',
			startDate: item.days?formattedDate(item?.days?.[0]?.date):'-',
			endDate: item.days?formattedDate(item?.days?.[item?.days?.length - 1]?.date):'-',
			time:`${item.startTime?formattedTime(item.startTime):'-'} to ${item.endTime?formattedTime(item.endTime):'-'}`,
			venue:item.venue,
			noOfRegisteredMembers:item.registeredMemberCount?item.registeredMemberCount:'0',
			noOfVisitors:item.visitorCount?item.visitorCount:'0',
			status:item.status?capitalizedStr(item.status):'-',
			action:item._id?item._id:'-',
			view:item
		}))

	} catch (error) {
		console.error('MEMBER LIST:', error);
		
	}

}

const eventStatusQuery = (type) =>{
	switch (type) {
	case 'Active Events':
		return 'status=active'
	case 'Event History':
		return 'status=completed'    
	default:
		return ''
	}
} 

const formattedDate = (d)=>{
	return moment(d).format('DD-MMM-YYYY');
}

const formattedTime = (t)=>{
	return moment(t).format('hh:mm A');
	// return moment.utc(t).format('hh:mm A');
}

const capitalizedStr = (str)=>{
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const deleteEventConfirmation = (id)=>{
	eventId.value = id;
	deleteDialog.value = true;
}


const deleteEvent = ()=>{
	axios
		.delete(`${process.env.VUE_APP_SERVICE_URL}/event/${eventId.value}`,
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			if(response.status === 200){
				deleteDialog.value=false;
				viewDialog.value=false;
				search.value = String(Date.now());
				snackbar.value=true;
				text.value = "Deleted Successfully"; 
				loadEventsCount();		
			}
		})
}

const editEvent = (data)=>{
  store.state.event.showAddEventDialog = true;
  store.state.event.eventFormMode = 'Edit';
  store.state.event.editEventData = data;
	
}
</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.heading{text-align: left;}
.tab-heading-and-action h3{font-size: 18px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}


.search-container{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
  .search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
 .search-container input{outline: unset;}


.filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
.filter-btn{position: relative;cursor: pointer;}
.filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
.filtercard select{border: 1px solid #B2B2B2;
    padding: 6px;
    margin-top: 12px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:#B2B2B2;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
  

.filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
.reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;}
.filter-close-btn{width: max-content;
    margin-left: auto;
    display: block;
   cursor: pointer;}

 
 /* datepicker    */
 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;padding: 7px;}
  .input-group-append {
  display: flex;
  align-items: center; 
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
 /* datepicker end */  
 .switcher img{width: 34px;
    margin-top: 7px;
    margin-left: 12px;
    cursor: pointer;}
 .add-event{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-event .fa-plus{margin-right: 8px;}
.v-table :deep() thead tr > th:nth-child(4){width: 210px;}
.v-table :deep() thead tr > th:nth-child(5){width: 130px;}
.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pencil{margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;}
.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}

 .view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;z-index: 20000;width:100%;height: 100vh;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 280px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}
  .gray-box{background-color: #EFEFEF;
    padding: 7px 11px;
    width: max-content;
    border-radius: 7px;
    margin-left: auto;} 
    .Ongoing,.Upcoming,.Completed{padding: 5px 6px;
    text-align: center;
    font-weight: 800;
    border-radius: 5px;}  
   .Ongoing{
    border: 2px solid #76C8A7;
    color: #76C8A7;
    } 
    .Upcoming{
    border: 2px solid #7381FF;
    color: #7381FF;
    } 
    .Completed{
    border: 2px solid #EA8B8B;
    color: #EA8B8B;
    } 
</style>

