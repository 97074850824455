<template>
  <v-dialog 
    v-model="store.state.event.showAddEventDialog"
    width="900"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;">
            {{ store.state.event.eventFormMode }} Event
          </h3>
          <div class="form-card">
            <form
              @submit.prevent="onSubmit"
            >
              <div class="form-row">
                <div class="form-col-full">
                  <label for="title">Title</label>
                  <input
                    v-model="eventFormData.title"
                    name="title"
                    type="text"
                    placeholder="Title"
                    @input="validateTitle"
                  >
                
                  <span
                    v-if="errors.title"
                    class="error-msg"
                  >{{ errors.title }}</span>
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-half">
                  <label for="label">Label</label>
                  <input
                    v-model="eventFormData.label"
                    name="label"
                    type="text"
                    placeholder="Label"
                    @input="validateLabel"
                  >
                  <span
                    v-if="errors.label"
                    class="error-msg"
                  >{{ errors.label }}</span>
                </div>
                <div class="form-col-half">
                  <label>Event Type</label> 
                  <div class="radio-group">
                    <div class="radio-input-wrap">
                      <input
                        id="virtual"
                        v-model="eventFormData.eventType"
                        type="radio"
                        name="eventType"
                        value="online"
                      >
                      <label for="virtual">Virtual</label>
                    </div>
                    <div class="radio-input-wrap">
                      <input
                        id="physical"
                        v-model="eventFormData.eventType"
                        type="radio"
                        name="eventType"
                        value="offline"
                      >
                      <label for="physical">Physical</label>
                    </div>
                  </div>
                  <!-- <input name="title" type="text"  placeholder="Title" />
                <ErrorMessage name="title" />  -->
                </div>
                <div class="form-col-half" />
              </div>


              <div class="form-row">
                <div class="form-col-full">
                  <label for="description">Description</label>
                  <input
                    v-model="eventFormData.description"
                    name="description"
                    v-bind="field"
                    type="text"
                    as="textarea"
                    placeholder="Description"
                    @input="validateDescription"
                  >
                  <span
                    v-if="errors.description"
                    class="error-msg"
                  >{{ errors.description }}</span>
                </div>
              </div>
              <div ref="daysContainer">
                <div
                  v-for="(field, idx) in eventFormData.days"
                  :key="field.key"
                  class="form-row"
                >
                  <div class="form-col-half">
                    <label for="day">Day {{ idx + 1 }}</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="eventFormData.days[idx].day"
                        :config="dateConfig(idx)"
                        class="date-form-control date-picker"
                        placeholder="Date"
                        name="day"
                        @change="validateDay(idx)"
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          colorpicker="colorpickere"
                          data-toggle
                        >
                          <fa icon="calendar" />
                          <span
                            aria-hidden="true"
                            class="sr-only"
                          >Toggle</span>
                        </button>
                      </div>
                    </div>
                    <span
                      v-if="errors.days[idx].day"
                      class="error-msg"
                    >{{ errors.days[idx].day }}</span>
                  </div>

                  <div class="form-col-half">
                    <label for="startTime">Start Time</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="eventFormData.days[idx].startTime"
                        :config="startTimeConfig(idx)"
                        class="start-time date-form-control"
                        placeholder="Start Time"
                        name="startTime"
                        @change="validateStartTime(idx)"
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          title="Toggle"
                          data-toggle
                        >
                          <fa icon="clock" />
                          <span
                            aria-hidden="true"
                            class="sr-only"
                          >Toggle</span>
                        </button>
                      </div>
                    </div>
                    <span
                      v-if="errors.days[idx].startTime"
                      class="error-msg"
                    >{{ errors.days[idx].startTime }}</span>
                  </div>


                  <div class="form-col-half">
                    <label for="endTime">End Time</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="eventFormData.days[idx].endTime"
                        :config="endTimeConfig(idx)"
                        class="end-time date-form-control"
                        placeholder="End Time"
                        name="endTime"
                        @change="validateEndTime(idx)"
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          title="Toggle"
                          data-toggle
                        >
                          <fa icon="clock" />
                          <span
                            aria-hidden="true"
                            class="sr-only"
                          >Toggle</span>
                        </button>
                      </div>
                    </div>
                    <span
                      v-if="errors.days[idx].endTime"
                      class="error-msg"
                    >{{ errors.days[idx].endTime }}</span>
                  </div>

 
                  <div class="form-col-half">
                    <label :for="`colorpicker_${idx}`">Colorpicker </label>
                    
                    <Picker
                      :id="`colorpicker_${idx}`"
                      v-model="eventFormData.days[idx].color"
                    />
                  </div>


                  <div class="form-col-half">
                    <div class="arr-btns-group">
                      <div
                        class="arr-btns"
                        @click="addRow()"
                      >
                        +
                      </div>
                      
                      <div
                        v-if="idx==0"
                        style="visibility:hidden"
                        class="arr-btns"
                        @click="remove(idx)"
                      >
                        -
                      </div>
                      <div
                        v-else
                        style="visibility:visible"
                        class="arr-btns"
                        @click="remove(idx)"
                      >
                        -
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="eventFormData.eventType=='offline'"
                class="form-row"
              >
                <div class="form-col-full">
                  <label for="venue">Venue</label>
                  <select
                    id="venue"
                    v-model="eventFormData.venue"
                    name="venue"
                    as="select"
                    @change="validateVenue"
                  >
                    <option value="">
                      venue
                    </option>
                    <option
                      v-for="item in venueList"
                      :key="item._id"
                      :value="item._id"
                    >
                      {{ item.name || '' }}
                    </option>
                  </select>
                  <span
                    v-if="errors.venue"
                    class="error-msg"
                  >{{ errors.venue }}</span>
                </div>
              </div>

              <div 
                v-if="eventFormData.eventType=='online'"
                class="form-row"
              >
                <div class="form-col-full">
                  <label for="meet_link">Meet Link</label>
                  <input
                    v-model="eventFormData.meetLink"
                    name="meet_link"
                    type="text"
                    placeholder="Meet Link"
                    @change="validateMeetLink"
                  >
                  
                  <span
                    v-if="errors.meetLink"
                    class="error-msg"
                  >{{ errors.meetLink }}</span>
                </div>
              </div>

              <div class="form-row">
                <div class="form-col-full">
                  <div class="form-actions">
                    <button
                      v-if="store.state.event.eventFormMode=='Create'"
                      type="button"
                      @click="resetForm"
                    >
                      Reset
                    </button>
                    <button type="submit">
                      {{ store.state.event.eventFormMode=='Create'?'Save':'Update' }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import axios from 'axios';
import {ref,watch,onMounted,inject } from 'vue'
import { useStore } from 'vuex';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.css';
import {updateEventData,getEventData, getVenueData } from '@/service/helper-service.js';
import { isoDateformat, formatTime} from '@/helper/index.js';
import Picker from '@/components/colorpicker/Picker.vue';
import "vue3-colorpicker/style.css";
import {useRouter } from 'vue-router';
import { reactive } from 'vue';
let pureColor = ref("orange");

const store = useStore(); 
const router = useRouter();

const addEventDialog =  ref(false);
const venueList = ref([]);
// let eventData = inject('eventData');
// let mode = inject('mode');

const initialState = reactive({
	title: '',
	label: '',
	eventType:'offline',
	description: '',
	days: [
		{'day': '',
			'startTime':'',
			'endTime': '',
			'color':'#eee'}
	],
	venue:'',
	meetLink:''  
})

const eventFormData = reactive({
	title: '',
	label: '',
	eventType:'offline',
	description: '',
	days: [
		{'day': '',
			'startTime':'',
			'endTime': '',
			'color':''}
	],
	venue:'',
	meetLink:''  
})



const errors = reactive({
	title: '',
	label: '',
	eventType:'offline',
	description: '',
	days: [
		{'day': '',
			'startTime':'',
			'endTime': '',
			'color':''}],
	venue:'',
	meetLink:''})



const validateTitle = ()=>{
	if (!eventFormData.title) {
		errors.title = 'Please enter title';
	} else {
		errors.title = '';
	}
}

const validateLabel = ()=>{
	if (!eventFormData.label) {
		errors.label = 'Please enter label';
	}else if(eventFormData.label.length > 6){
		errors.label = 'Label should be less than 6 characters';
	}else {
		errors.label = '';
	}
}

const validateDescription = ()=>{
	if (!eventFormData.description) {
		errors.description = 'Please enter description';
	} else {
		errors.description = '';
	}
}

const validateMeetLink = ()=>{
	if (!eventFormData.meetLink) {
		errors.meetLink = 'Please enter meet link';
		return false;
	} else {
		errors.meetLink = '';
		return true;
	}
}


const validateVenue = ()=>{
	if (!eventFormData.venue) {
		errors.venue = 'Please enter venue';
		return false;
	} else {
		errors.venue = '';
		return true;
	}
}

const validateEventType = ()=>{
	if (eventFormData.eventType == 'offline') {
		return validateVenue()
	} 
  if (eventFormData.eventType == 'online') {
		return validateMeetLink()
	} 
}

const validateDay = (index)=>{
	if (!eventFormData.days[index].day) {
		errors.days[index].day = 'Please enter date';
		return false;
	} else {
		errors.days[index].day = '';
		return true;
	}
}

const validateStartTime = (index)=>{
	if (!eventFormData.days[index].startTime) {
		errors.days[index].startTime = 'Please select start time';
		return false;
	} else {
		errors.days[index].startTime = '';
		return true;
	}
}

const validateEndTime = (index) => {
	if (!eventFormData.days[index].endTime) {
		errors.days[index].endTime = 'Please select end time';
		return false;
	} else {
		errors.days[index].endTime = '';
		return true;
	}
};

const validateDays = () => {
	let isValid = true; // Assume all days are valid initially

	for (let i = 0; i < eventFormData.days.length; i++) {
		// Perform validations
		validateDay(i);
		validateStartTime(i);
		validateEndTime(i);

		// If any validation fails, set isValid to false
		if (!validateDay(i) || !validateStartTime(i) || !validateEndTime(i)) {
			isValid = false;
		}
	}

	return isValid; // Return the result after all days are validated
};

watch(() => store.state.event.showAddEventDialog, (newValue) => {
	console.log("newValue", newValue);
	if(newValue){
		let evData = store.state.event.editEventData;
		// addEventDialog.value = true;
		store.state.event.showAddEventDialog = true;
		if(store.state.event.eventFormMode == 'Edit'){
			let mappedDays = (evData?.days ?? []).map(item => ({
				day: isoDateformat(item.date),
				startTime: formatTime(item.startTime),
				endTime: formatTime(item.endTime),
				color: item.color
			}));

			let mappedErrors = (evData?.days ?? []).map(item => ({
				day: '',
				startTime: '',
				endTime: '',
			}));
    
		
			store.state.event.eventId = evData?._id;
			eventFormData.title = evData?.eventName;
			eventFormData.label = evData?.label;
			eventFormData.description = evData?.description;
			eventFormData.eventType = evData?.type;
			eventFormData.venue = evData?.venueDetail?._id;
			errors.days = [...mappedErrors];
			eventFormData.days = [...mappedDays]
  
		}
	}
});

  
onMounted(()=>{
	loadVenueList(); 
})

  

const closeDialog = ()=>{
	resetForm();
	store.state.event.eventFormMode = 'Create'
	// addEventDialog.value = false;
	store.state.event.showAddEventDialog = false;
}


const daysContainer = ref(null);
const today = new Date().toISOString().split('T')[0];

const dateConfig = (index) => ({
	wrap: true,
	altInput: true,
	altFormat: "j-M-Y",
	dateFormat: "Y-m-d",
	disableMobile: true,
	minDate: store.state.event.eventFormMode!='Edit'?today:null,
	// defaultDate: eventFormData.days[index].day ? new Date(eventFormData.days[index].day) : today
	// default:eventFormData.days[index].day,
	onOpen: (selectedDates, dateStr, instance) => {
		if (index > 0 && daysContainer.value) {
			const inputs = daysContainer.value.querySelectorAll('.date-picker.flatpickr-input');
      
			// Find the latest non-empty date from previous inputs
			let latestPreviousDate = null;
			for (let i = 0; i < index; i++) {
				if (inputs[i] && inputs[i].value) {
					const currentDate = moment(inputs[i].value);
					if (!latestPreviousDate || currentDate.isAfter(latestPreviousDate)) {
						latestPreviousDate = currentDate;
					}
				}
			}

			// Set minDate if a valid previous date was found
			if (latestPreviousDate) {
				const minDate = latestPreviousDate.add(1, 'days').format('YYYY-MM-DD');
				instance.set('minDate', minDate);
			} else {
				// If no valid previous date, reset to today
				instance.set('minDate', 'today');
			}
		}
    
	},
	// onchange: (selectedDatesValue, dateStr) => {
	// 	console.log("dateStr", dateStr);
	// 	// selectedDates.value[idx] = dateStr;
	// 	console.log("selectedDatesValue", selectedDatesValue);
	// 	if(dateStr){
	// 		initialData.days[index].day = selectedDatesValue;
	// 	}
	// },
});


const startTimeConfig = (index) => ({
	wrap: true, // set wrap to true only when using 'input-group'
	dateFormat: "H:i",
	noCalendar: true,
	enableTime:true,
	time_24hr: true,
	minuteIncrement: 15,
	// minTime: "09:00",
	// defaultDate: eventFormData.days[index].startTime, // Set default time
});

const endTimeConfig = (index) => ({
	wrap: true, // set wrap to true only when using 'input-group'
	dateFormat: "H:i",
	noCalendar: true,
	enableTime:true,
	time_24hr: true,
	minuteIncrement: 15,
	// minTime: "09:00",
	onOpen: (selectedDates, dateStr, instance) => {
		// const startTime = daysContainer.value.querySelectorAll('.start-time.flatpickr-input');
		// instance.set('minTime', moment(startTime[index].value, "HH:mm").add(1, 'hours').format("HH:mm") );
		const formRow = instance.element.closest('.form-row');
		// Find the start time input within this form-row
		const startTimeInput = formRow.querySelector('.start-time.flatpickr-input');
    
		if (startTimeInput && startTimeInput.value) {
			const minTime = moment(startTimeInput.value, "HH:mm").add(1, 'hours').format("HH:mm");
			instance.set('minTime', minTime);
		}
	}
  
});



const onSubmit = async() => {
	
	console.log(eventFormData)
	validateTitle()
	validateLabel()
  validateEventType()
	validateDescription()
	validateDays()
	if(!errors.title && !errors.label && validateEventType() && !errors.description && validateDays()){
		console.log("success");
		const mappedData = eventFormData.days.map((item,index) => {
   
			return {
				date:item.day,
				day: index + 1,
				startTime: new Date(`${item.day} ${item.startTime}`).toISOString(),
				endTime: new Date(`${item.day} ${item.endTime}`).toISOString(),
				color:item.color
			};
 
		});
		let method = store.state.event.eventId? 'put':'post';
		let url = store.state.event.eventId? `/event/${store.state.event.eventId}`:`/event/`;
		try {
			const response = await updateEventData(method,url,
				{
					"eventName": eventFormData.title,
					"description": eventFormData.description,
					"venueId": eventFormData.venue?eventFormData.venue:'',
					"type": eventFormData.eventType,
					"label": eventFormData.label,
					"mapLink": eventFormData.meetLink?eventFormData.meetLink:'',
					"days": mappedData
				}
			);
			if(response.status === 200){
				console.log('response',response?.data?.data);
				store.state.event.isEventUpdated = true;
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:store.state.event.eventFormMode=='Edit'?'Updated successfully':'Created successfully',
					color:'success',
					timeout:2000});
				closeDialog();
				if(!store.state.event.eventId){
					let id = response?.data?.data;
					router.push({ name: 'scheduler', params: { id} });
					
				}
			}
		} catch (error) {
			if (error.response) {
				console.log('GET EVENT',error.response);
			}
		}
		

	}else{
		console.log("failed");
	}
}
	


const addRow = () => {
	if(validateDays()){
		eventFormData.days.push({ day: '', startTime: '', endTime: '', color: '' });
		errors.days.push({ day: '', startTime: '', endTime: '', color: '' });
	}
};

const remove = (index) => {
	eventFormData.days.splice(index, 1);
};


const loadVenueList = async()=>{
	try {
		const response = await getVenueData(`/venue?type=all`);
		if(response.status === 200){
			console.log('GET Venue response',response?.data?.data)
			let venueData = response?.data?.data;
			venueList.value =  venueData?.map(item => ({
				_id: item._id,
				name: item.name,
			}));
		}} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}


const resetForm = ()=>{
	Object.assign(eventFormData, JSON.parse(JSON.stringify(initialState)))
	setTimeout(() => {
		Object.assign(errors, JSON.parse(JSON.stringify(initialState)))
	},100)
}

</script>

<style scoped>
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */

.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='text'] + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
/* .form-col-half{flex-basis: 50%;}
.form-col-full{flex-basis: 100%;} */
.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}

/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
/* .input-group input{border:unset;outline: unset;} */
/* .flat-pickr{width:100%!important;}  */
/* .flatpickr-input{width: 30%;background-color: #007CBA;} */
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}
.arr-btns-group{display: flex;
    align-items: self-end;
    height: 100%;}
.arr-btns{background-color: #007CBA;
    color: white;
    width: 39px;
    height: 37px;
    border-radius: 5px;
    margin-bottom: 3px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;}
    select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
} 
</style>
