<template>
  <div
    class="color-picker"
  >
    <div class="color-options">
      <div
        v-for="(color, index) in colors"
        :key="index"
        :style="{ backgroundColor: color }"
        class="color-circle"
        @click="setColor(color)"
      />
      <div
        class="fill-icon"
        @click="togglePalette"
      >
        <label
          :for="id"
          class="cursor-pointer"
        > 
          <fa icon="fill-drip" />
          <input
            :id="id"
            v-model="selectedColor"
            type="color"
            style="visibility: hidden;"
            @input="updateColor"
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch, defineProps, defineEmits} from 'vue';
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
	id: {
		type: String,
		required: true
	},
	initialColors: {
		type: Array,
		default: () => ['#ff66ff', '#ffcc66', '#ff6666', '#6666ff']
	},
	modelValue: {
		type: String,
		default: '#ffcc66'
	}
});

const colors = ref([...props.initialColors]);
const selectedColor = ref(props.modelValue);
const showPalette = ref(false);

const togglePalette = ()=> {
	showPalette.value = !showPalette.value;
}
    
const setColor = (color)=> {
	selectedColor.value = color;
	colors.value[0] = color;
	emit('update:modelValue', color); 
}

const updateColor = (event)=> {
	// colors.value[0] = event.target.value;
	setColor(event.target.value);
}

onMounted(()=>{
	console.log("mounted");
	setColor(props.modelValue);
})


watch(() => props.modelValue, (newValue) => {
	selectedColor.value = newValue;
});
</script>

<style scoped>


.color-options {
  display: flex;
  align-items: center;
  gap: 10px;
  /* min-width: 219px; */
}

.color-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.color-circle:nth-child(1){
  width: 30px;
  height: 30px;
}

.fill-icon {
  cursor: pointer;
  font-size: 20px;
  width: 22%;
  height: 30px;
}

.color-palette {
  margin-top: 10px;
}

.cursor-pointer {
    cursor: pointer;
}
input[type="hidden"] {
    width: 11px; 
}    
</style>

