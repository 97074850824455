<template>
  <div
    :id="'option-dropdown-'+track.id"
    class="option-dropdown"
    :data-dropdown-id="track.id"
  >
    <div
      class="disable-option pointer"
         
      :data-hide-track-id="track.id"
      @click="closeTrackOption($event)"
    >
      &#128473;
    </div>
    <div
      class="menu-item pointer edit-track"
      :data-id="track.id"
      :data-name="track.name"
      :data-color="track.color"
      :data-date="date"
      @click="editTrackForm($event)"
    >
      <fa 
        icon="pen-to-square"
      />Edit
    </div>
    <div
      class="menu-item pointer delete-track" 
      :data-id="track.id"
      :data-name="track.name"
      :data-date="date"
      @click="deleteTrackConfirmationDialog($event)"
    >
      <fa 
        icon="trash"
      />Delete 
    </div>
  </div>
</template>

<script setup>
import { defineProps, inject } from 'vue';
const hideTrackOption = inject('hideTrackOption');
const editTrack = inject('editTrack');
const deleteTrackConfirmation = inject('deleteTrackConfirmation');

defineProps({
	track: {
		type: Object,
		required: true,
		default: () => ({})
	},
	date: {
		type: String,
		required: true
	}
});

const closeTrackOption = (event)=>{
	hideTrackOption(event);
}

const editTrackForm = (event)=>{
	editTrack(event);
}

const deleteTrackConfirmationDialog = (event)=>{
	deleteTrackConfirmation(event);
}


</script>

<style scoped>
.close-icon{ 
    width: 18px;
    color: gray;
    position: absolute;
    right: 6px;
    top: 2px;
    font-size: 14px;
	}	
.option-dropdown,.event-option-dropdown{
    display:none;
    position: absolute;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 16px 10px 10px;
    border-radius: 9px;
    top:0;
    right: 0;
    z-index: 1040;
}
					.option-dropdown{width: 100px;}
					.event-option-dropdown{width: 100px;right:0px;}	
				.menu-item{font-size: 14px;text-align:left;}
				.menu-item .fa-pen-to-square, .menu-item .fa-trash{margin-right: 9px;}
				.menu-item .fa-trash{color:red;}	
				.disable-option,.disable-event-option{position: absolute;
					right: 7px;
					top: 8px;
					color: gray;}
</style>