<template>
  <div
    ref="dateColumn"
    class="date-column"
    :style="'background-color:' +item.color"
    :data-date="item.date"
    @dragStart="dragEventStart"
    @dragover="allowDropEvent($event)"
    @drop="handleDropEvent($event)"
  >
    <div class="date-label">
      <div v-html="formattedDateWithOrdinal(item.date)" />
    </div>
    <slot />
    <button
      class="add-track"
      @click="openTrackForm(item.date)"
    >
      <span>&#xFF0B;</span> Add Track
    </button>
  </div>
</template>

<script setup>
import { defineProps, inject } from 'vue';
import { formatTime, formattedDateWithOrdinal} from '@/helper/index.js';
const handleDrop = inject('handleDrop');
const allowDrop = inject('allowDrop');
const onEventDragStart = inject('onEventDragStart');
const addTrack = inject('addTrack');

defineProps({
	item: {
		type: Object,
		required: true,
		default: () => ({})
	}
});

const handleDropEvent = (event) => {
	handleDrop(event);
};

const allowDropEvent = (event) => {
	allowDrop(event);
};

const dragEventStart = (event) => {
	onEventDragStart(event);
};

const openTrackForm = (date) => {
	addTrack(date);
}


</script>

<style scoped>
.date-column{ flex: 0 0 auto;transition:  0.8s ease;position: relative;}
.date-column:hover{box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;border: 1px transparent;transition: .4s ease-in-out;}
.date-column:hover .add-track{display: flex;}          
.date-label{text-align: center;
    font-size: 14px;
    margin: 3px 0 4px;
    font-family: 'Poppins', sans-serif;}
.add-track{position: absolute;
    right: 0px;
    top: 344px;
    padding: 0px 12px 0px 0px;
    background-color: #007cba;
    z-index: 1040;
    display: none;
    align-items: center;
    border-radius: 16px 1px 16px 16px;
    color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 16px;

    transition: all 0.6s ease-in;}
.add-track span{color: #fff;
    font-size: 28px;
    padding: 0px 4px 1px 11px;}    
</style>

