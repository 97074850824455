<template>
  <div class="form-card">
    <Form
      ref="form"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <div class="form-row">
        <div class="form-col-half">
          <label for="firstName">First Name<span>*</span></label>
          <Field
            name="firstName"
            type="text"
            placeholder="First Name"
          />
          <ErrorMessage name="firstName" /> 
          <span />
        </div>
        <div class="form-col-half">
          <label for="middleName">Middle Name</label>
          <Field
            name="middleName"
            type="text"
            placeholder="Middle Name"
          />
          <ErrorMessage name="middleName" /> 
        </div>

        <div class="form-col-half">
          <label for="lastName">Last Name</label>
          <Field
            name="lastName"
            type="text"
            placeholder="Last Name"
          />
          <ErrorMessage name="lastName" /> 
        </div>
      </div>

      <div class="form-row">
        <div class="form-col-half gender">
          <label for="gender">Gender</label>

          <Field
            name="gender"
            as="select"
          >
            <option
              value=""
              selected
              hidden 
              disabled
            >
              Gender
            </option>
            <option value="Male">
              Male
            </option>
            <option value="Female">
              Female
            </option>
            <option value="Other">
              Other
            </option>
          </Field>
      
          <ErrorMessage name="gender" />
        </div>
 
        <div class="form-col-half">
          <label for="phoneNumber">Phone Number<span>*</span>  </label>
     

          <!-- <Field
            id="phoneNumber"
            v-slot="{field}"
            name="phoneNumber"
            type="tel"
          >
            <vue-tel-input
              v-bind="field"
              v-model="field.value"
            /> 
          </Field>
          <ErrorMessage name="phoneNumber" /> -->


          <Field
            id="phoneNumber"
            v-slot="{field}"
            v-model="phoneNumber"
            name="phoneNumber"
            type="tel"
            @input="phoneNumberChanged"
            @blur="phoneNumberChanged"
          >
            <vue-tel-input
              v-bind="field"
              v-model.trim="field.value"
              :input-options="options"
              @country-changed="countryChanged"
            /> 
          </Field>
          <span>     
            <ErrorMessage
              v-if="isPhoneNumberTouched"
              name="phoneNumber"
            />
          </span>
        </div>
    

        <div class="form-col-half">
          <label for="email">Email</label> 
      
          <Field
            name="email"
            type="text"
            placeholder="Email"
          />
          <ErrorMessage name="email" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-col-half">
          <label for="chapter">Chapter<span>*</span></label> 
          <Field
            name="chapter"
            type="text"
            placeholder="Chapter"
          />
          <ErrorMessage name="chapter" />
        </div>
        <div class="form-col-half" />
        <div class="form-col-half" />
      </div>

      <div class="form-row">
        <div class="form-col-full">
          <div class="form-actions">
            <button
              type="button"
              @click="resetForm"
            >
              Reset
            </button>
            <button
              type="submit"
              :disabled="isDisabled"
            >
              Save
              <fa
                v-show="spinner===true"
                icon="spinner"
                spin
              />
            </button>
          </div>
        </div>
      </div>
    </Form>
  </div>
   
  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>





<script setup>
import {ref} from 'vue'
import { isValidPhoneNumber} from "libphonenumber-js/mobile"; 
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import { useStore } from 'vuex';

const store = useStore(); 
// const firstName = ref('');
// const middleName = ref('');
// const lastName = ref('');
// const gender = ref('');
// const email = ref('');
const phoneNumber =ref('');
const form  = ref();
const dialCode = ref('');
// const chapter = ref('');
const	timeout= ref(2000);
const snackbar= ref(false);
const text= ref('');
const spinner = ref(false);
const isDisabled = ref(false);


const isPhoneNumberTouched = ref(false);
const isDobTouched = ref(false);

const phoneNumberChanged = () => {
	isPhoneNumberTouched.value = true;
}

const dobChanged = () => {
	isDobTouched.value = true;
}


const options = ref({ placeholder: "Phone Number" })

const schema = yup.object({
	firstName: yup.string().required('Please enter firstname')
		.matches(/^[A-Za-z]*$/, 'Only letters are allowed'),
	middleName: yup.string().matches(/^[A-Za-z]*$/, 'Only letters are allowed'),
	lastName: yup.string().matches(/^[A-Za-z]*$/, 'Only letters are allowed'),
	phoneNumber:yup.string()
		.test('phone-validation', 'Please enter Number', function(value) {
			if (!value) {
				return false; // Validation failed
			}
			return true;
		})
		.test('invalid-validation', 'Invalid Phone Number', function(value) {
			if (value && !isValidPhoneNumber(value)) {
				return false; // Validation failed
			}
			return true;
		}).nullable(),
	email: yup.string('Please enter email').email('Invalid Email'),
	chapter: yup.string().required('Please enter chapter').matches(/^[A-Za-z]*$/, 'Only letters are allowed'),
});

const countryChanged = (country) =>{
	console.log(country);
	dialCode.value = country.dialCode;
}

const onSubmit = (data) => {
	console.log('data',data);
	isDisabled.value = true;
	spinner.value = true;
 	axios
		.post(`${process.env.VUE_APP_SERVICE_URL}/member`,
			[{
				"firstName": data.firstName,
				"middleName":data.middleName,
				"lastName": data.lastName,
				"email": data.email,
				"phone": {
					"dialCode": '+'+dialCode.value,
					"number":data.phoneNumber.replace(data.phoneNumber.split(' ')[0],'').replace(/\D/g, '')
				},
				"gender":data.gender,
				"state":data.chapter
        
			}],
			{headers:{
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log('response',response.status);
			if(response.status === 200){
          
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Created Successfully',
					color:'success',
					timeout:2000});

				resetForm();
			}

      
		})
		.catch((error)=>{
			if (error.response) {
				console.log(error.response);
				if(error.response.status === 400){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Phone Number already exists',
						color:'danger',
						timeout:2000});
				}
			}})
		.finally(() => {
			isDisabled.value = false;
			spinner.value = false;
		});

	
	
};



const resetForm = ()=>{
	form.value.resetForm()  
	setTimeout(()=>{
		form.value.setFieldError('phoneNumber', '') 
	},100)
}


</script>




<style scoped>
.form-card{box-shadow: 0px 10px 15px 0px rgba(124, 124, 124, 0.20);border-radius: 12px;width: 80%;margin: 25px auto 0;
background-color:white;padding: 17px;}
.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input + span ,.vue-tel-input + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex-direction: column;flex:1;margin-right:30px;margin-bottom: 20px;}
.form-col-half:last-child{margin-right:0px;}
.gender{flex-basis: 20%!important;flex:unset;}
/* .form-col-half{flex-basis: 50%;} */
/* .form-col-full{flex-basis: 100%;} */
.form-col-half input,.form-col-full input,.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
    height: 38px;padding: 4px 13px;width:100%;}
 .form-col-half input:focus,.form-col-full input:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}
:deep() .iti__selected-flag{background-color:unset;}
select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
</style>

