<template>
  <div
    
    v-if="visible"
    class="dialog-overlay"
  >
    <div
      class="dialog"
      :style="{ backgroundColor: dialogBg}"
    >
      <div
        class="close-icon"
        @click="cancel"
      >
        <fa icon="close" />
      </div>
      <Form
        ref="form"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <h3 class="dialog-title">
          {{ store.state.event.trackFormMode }} Track 
        </h3>
     
        <div class="dialog-body">
          <div class="form-row">
            <div class="track-name-col">
              <label for="trackName">Track Name<span>*</span> </label>
              <Field
                v-model="trackName"
                type="text"
                name="trackName"
                placeholder="Track Name"
              />
              <ErrorMessage name="trackName" />
              <div
                v-if="errMessage!=''"
                class="err-message"
              >
                {{ errMessage }}
              </div>
            </div>
            <div class="colorpicker-col">
              <label for="colorpicker">Colorpicker</label>
              <Picker
                :id="'color-picker'"
                v-model="pureColor"
                style="margin-top: 9px;"
                :initial-colors="['#9DBDFF', '#FF8343', '#BEDC74']"
              />
              <Field
                v-model="pureColor"
                type="hidden"
                name="pureColor"
              />
              <ErrorMessage name="pureColor" />
            </div>
          </div> 
          
          <div class="dialog-action">
            <button
              v-if="store.state.event.trackFormMode =='Add'"
              class="info"
              type="button"
              @click="resetForm"
            >
              {{ resetText }}
            </button>
            <button
              :class="buttonBg"
              type="submit"
            >
              {{ confirmText }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import {ref, watch, inject} from 'vue'
import { useStore } from 'vuex';
import "vue3-colorpicker/style.css";
import { Form, Field, ErrorMessage} from 'vee-validate';
import Picker from '@/components/colorpicker/Picker.vue';
import * as yup from 'yup';
import {updateTrackData} from '@/service/helper-service.js';

const store = useStore(); 
const emit = defineEmits(['submit', 'reset' , 'cancel']);

const props = defineProps({
	dialogBg: {
		type: String,
		required: false,
		default:'#fff'
	},
	resetText: {
		type: String,
		required: false,
		default:'Reset'
	},
	buttonBg: {
		type: String,
		required: false,
		default:'info'
	},
	confirmText: {
		type: String,
		required: false,
		default:'Confirm'
	}

  
})

let openTrackDialog = inject('openTrackDialog');
const trackName = ref('');
const pureColor = ref('orange');
const errMessage = ref('');
const editTrackId = ref('');
const mode = ref('');
const form = ref(null);
let visible = ref(false);


const schema = yup.object({
	trackName:yup.string().required('Please enter Track name'),
	pureColor:yup.string().required('Please select Track color')
});


watch(openTrackDialog, (newValue) => {
	console.log("newValue",newValue.status);
	visible.value = newValue.status;
	if(store.state.event.trackFormMode ==='Edit'){
		trackName.value = store.state.event.editTrackData.trackName;
		pureColor.value = store.state.event.editTrackData.trackColor;
		editTrackId.value = store.state.event.editTrackData.trackId;
	}else{
		trackName.value = '';
		pureColor.value = '#9DBDFF';
		editTrackId.value = '';
	}
});






const onSubmit = async(data) => {
	console.log("data",data)
	updateTrack(data.trackName, store.state.event.selectedTrackDate, pureColor.value, store.state.event.eventId);
	// generateRandom3Digit()
	
}


const resetForm = ()=>{
	form.value.resetForm() 
	trackName.value = '';
	pureColor.value = '#9DBDFF';
	editTrackId.value ='';
}




const cancel = () =>{
	resetForm();
	openTrackDialog.status = false;
}


const updateTrack = async(name, date, color, eventId) => {
	try {

		let payload = {
			"name": name,
			"date": date,
			"color": color,
			"eventId": eventId
		}
		const method = store.state.event.trackFormMode == 'Add'?'post':'put';
		const uri = store.state.event.trackFormMode == 'Add'?`/track`:`/track/${editTrackId.value}`;
		const response = await updateTrackData(method,uri,payload );
		if(response.status === 200){
			console.log('Updated track',response);
	     let trackId = response?.data?.data._id;
			
			if(store.state.event.trackFormMode == 'Add'){
				store.state.event.eventDates.find(x => x.date == store.state.event.selectedTrackDate).tracks.push({
					id:`${trackId}`,
					name:payload.name,
					color:payload.color
				})
			}else{
				let updateTrackField = store.state.event.eventDates.find(x => x.date == store.state.event.selectedTrackDate).tracks;
				let trackIndex = updateTrackField.findIndex(track => track.id === editTrackId.value);

				if (trackIndex !== -1) {
					updateTrackField[trackIndex].name = name;
					updateTrackField[trackIndex].color = color;
				} 
			}
			openTrackDialog.status = false;
			resetForm()
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:store.state.event.trackFormMode == 'Add'?'Added':'Updated'+' Successfully',
				color:'success',
				timeout:2000});
			// let mappedTopics = response?.data?.data.map(topic => ({ 'id':topic._id,
			// 	'title':topic.title,
			// 	'tag':topic.tag,
			// 	'description':topic.description,
			// 	'keywords':topic.keywords,
			// 	'eventId':topic.eventId,
			// 	'hallId':topic.hallId,
			// 	'speakers':topic.speakers,
			// 	...(topic?.speakerDetails && { speakerDetails: topic?.speakerDetails }),
			// 	'duration':topic.duration,
			// 	'type':topic.type
			// }))
			// store.state.event.topics = mappedTopics;
			
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}
</script>

<style scoped>
.form-row{display: flex;justify-content: space-between;}
.dialog-title{display: flex;align-items: center;margin-bottom: 6px;}
.track-name-col{flex-basis: 60%;}
.colorpicker-col{flex-basis:30%;margin-left: 12px;}
.track-name-col span{color: red;font-size: 14px;}
.dialog-title .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
 .dialog-body{font-size: 16px;font-weight: 500;}  
 .dialog-body input{width: 100%;
    border: 1px solid gray;
    border-radius: 4px;
    height: 40px;
    margin: 3px 2px 0px 2px;
    background: white;
    padding: 12px;} 
    .dialog-body label{color:#007CBA;font-weight: 700;
    font-size: 16px;}
 .dialog-body label span{color: red;}   
.dialog-body .err-message{color: red;font-size: 14px;margin-top: 4px;}   
.dialog-action{display: flex;justify-content: flex-end;margin-top: 12px;}
.dialog-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.danger{background-color: #D92D20;color: white;border-color:#D92D20;}
.success{background-color: white;border: 1px solid #8BC34A;
    color: #8BC34A;}
.info{background-color: #007CBA!important;border: 1px solid #007CBA!important;
    color: white!important;}    
.dialog-action button{padding: 8px 13px;
    border-radius: 9px;
    border: 1px solid;}

    .dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}
.dialog {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 12px;
    width: 498px;
}
.close-icon{position: absolute;right: 19px;top: 15px;cursor: pointer;}


</style>