<template>
  <div style="position: fixed;margin:auto;display: block;width: 100%;">
    <AdminPanel>
      <v-container>
        <div>
          <VenueList />
        </div>
      </v-container>
    </AdminPanel>
  </div>
</template>


<script>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import VenueList from '@/components/venue_management/VenueList.vue'

export default {
	components: {
		AdminPanel,
		VenueList

	},
	data(){
		return {
			selectedInfoTab:'ActiveMembers'
		}},
	methods:{
		selectInfoCard(tabName){
			this.selectedInfoTab = tabName;
           
		}
	}
}
</script>

<style scoped>
  .card-container{display: flex;justify-content: space-evenly;margin-top: 22px;}
   .card{border-radius: 6px;
    border: 1px solid #d3d2d2;
    background-color: #f7f6f6;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 13px;
    cursor: pointer;
    
    }
    .card:nth-child(1){margin-left: unset;}
  .card:hover,.card.active{background-color: #007CBA;color:#fff;transition: ease-in-out .8s;}
  .card-group{display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;}
 
.card-name{font-weight: 600;margin-top: 12px;font-size: 14px;text-align: left;}
.card .svg-inline--fa{padding: 8px;border-radius: 50%;font-size: 21px;}
.card:nth-child(1) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(2) .svg-inline--fa{color:#F2881D;background-color: #EFE3D7;}
.card:nth-child(3) .svg-inline--fa{color: #3632D4;background-color: #cbcade;}
.card:nth-child(4) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.card:nth-child(5) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
</style>


