<template>
  <AdminPanel>
    <AddTopic
      :event-id="eventId"
      :mode="store.state.event.scheduleFormMode"
      :event-dates="store.state.event.eventDates"
      :editable-event="editId"
    />
    <!-- <v-container> -->
    <div class="schedule-container">
      <div class="topic-section">
        <div class="topic-action">
          <h3>Topics</h3>
          <div
            class="add-event"
            @click="addTopic"
          >
            <fa icon="plus" /> Add New 
          </div>
        </div>
        

        <div
          class="topic-list"
        >
          <TopicCard 
            v-for="(item,index) in store.state.event.topics"
            :key="index"
            :item="item"
            :index="index"
            :class="'external-event'"
            :data-id="item.id"
            :data-duration="item.duration"
            :data-title="item.title"
            :draggable="true"
            @dragstart="onEventDragStart($event)"
            @dragover="onEventDragOver($event)"
            @drag="onDrag($event)"
            @delete-topic="deleteTopic(item.id)"
            @edit-topic="editTopic(item.id)"
          />
        </div>
      </div>
      <div class="schedule-grid">
        <ScheduleCalendar
          :event-id="eventId"
        />
      </div>
    </div>
    <!-- </v-container> -->
  </AdminPanel>
</template>


<script setup>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import AddTopic from '@/components/event_management/scheduler/AddTopic.vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ScheduleCalendar from '@/components/event_management/scheduler/ScheduleCalendar.vue'
import TopicCard from '@/components/event_management/scheduler/TopicCard.vue'
import {ref, reactive, provide, onMounted, onServerPrefetch, watch} from 'vue';
import { getQueueData, removeTopic} from '@/service/helper-service.js';

let openTopicDialog = reactive({'status':false});
provide('openTopicDialog',openTopicDialog)


const store = useStore(); 
const topicList = ref(store.state.event.topics);
// const topicList = reactive([
// 	{
// 		"id": "666a9bbc6452db00148ed308",
// 		"title": "dsfsdf",
// 		"tag": "",
// 		"description": "",
// 		"keyword": "topic.keyword",
// 		"eventId": "651fd2d7c35dcd34c4e8e111",
// 		"speakers": [],
// 		"type": "draft"
// 	},
// 	{
// 		"id": "666aa3336452db00148ed358",
// 		"title": "fdfsdf",
// 		"tag": "",
// 		"description": "",
// 		"keyword": "topic.keyword",
// 		"eventId": "651fd2d7c35dcd34c4e8e111",
// 		"speakers": [],
// 		"type": "draft"
// 	},
// 	{
// 		"id": "666d9f9b798e76001394c97f",
// 		"title": "feff",
// 		"tag": "",
// 		"description": "",
// 		"keyword": "topic.keyword",
// 		"eventId": "651fd2d7c35dcd34c4e8e111",
// 		"speakers": [],
// 		"type": "draft"
// 	},
// 	{
// 		"id": "666db11d798e76001394cbcb",
// 		"title": "dfdfdf",
// 		"tag": "",
// 		"description": "",
// 		"keyword": "topic.keyword",
// 		"eventId": "651fd2d7c35dcd34c4e8e111",
// 		"speakers": [],
// 		"type": "draft"
// 	}
// ]);
const route = useRoute();
const eventId = ref(route.params.id);
const editId = ref('')

const addTopic = () =>{
	store.state.event.scheduleFormMode ='Add';
	openTopicDialog.status = true
}

// const TopicCard = defineAsyncComponent(()=>import('@/components/event_management/scheduler/TopicCard.vue'))

const onEventDragStart  = (e, draggable)=>{
	console.log('onEventDragStar',e);
	// Passing the event's data to Vue Cal through the DataTransfer object.
	e.dataTransfer.setData('event', JSON.stringify(draggable))
	e.dataTransfer.setData('cursor-grab-at', e.offsetY)
}

const deleteTopic = async(id) =>{

	try {
		const response = await removeTopic(`/schedule/${id}`);
		if(response.status === 200){
			console.log('DelteE',response);
			let topicIndex = store.state.event.topics.findIndex(topic => topic.id == id)
			store.state.event.topics.splice(topicIndex,1)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deleted Successfully',
				color:'success',
				timeout:2000});
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}


const editTopic = (id) =>{
	console.log('id',id)
	store.state.event.scheduleFormMode ='Edit';
	store.state.event.editTopicId = id;
	openTopicDialog.status = true;
}

onMounted(async ()=>{
	
	loadQueue(route.params.id)

	console.log("store.state.event.topics",store.state.event.topics)
})



const loadQueue = async(id)=>{
	try {
		const response = await getQueueData(`/schedule?eventId=${id}&type=pending`);
		if(response.status === 200){
			console.log('Load QUEUE',response);
	
			let mappedTopics = response?.data?.data.map(topic => ({ 'id':topic._id,
				'title':topic.title,
				'tag':topic.tag,
				'description':topic.description,
				'keywords':topic.keywords,
				'eventId':topic.eventId,
				'hallId':topic.hallId,
				'speakers':topic.speakers,
				...(topic?.speakerDetails && { speakerDetails: topic?.speakerDetails }),
				'duration':topic.duration,
				'type':topic.type
			}))
			store.state.event.topics = mappedTopics;
			
		}
	} catch (error) {
		if (error.response) {
			console.log('',error.response);
		}
	}
}




</script>

<style scoped>
.schedule-container{display:inline-flex;width: 100%; padding: 12px;}
.topic-section{
  width: 30%;
    height: 100vh;
    margin-right: 12px;
    border-radius: 0 12px 0 0;
    margin-left: -15px;
    margin-top: -10px;
    border: 1px solid #CACACA;
    border-bottom: 0;
    border-left: 0;
    }
    .topic-action{border-bottom: 1px solid #9E9E9E;
    margin: 10px;display: flex;justify-content: space-between;align-items: center;
    padding-bottom: 10px;}  
    .topic-list{height: 450px;overflow-y:auto;}
    .topic-list::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.topic-list::-webkit-scrollbar
{
	width: 7px;
	background-color: #F5F5F5;
}
.topic-list::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
    .add-event{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;}
.add-event .fa-plus{margin-right: 8px;}


.schedule-grid{width: 70%;}
</style>


