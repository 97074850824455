import { createApp } from 'vue'
import store from './store'
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
//font awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

//VueTelInput
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

// Datepicker


//font family
import '@fontsource/mulish/200.css';
import '@fontsource/mulish/300.css';
import '@fontsource/mulish/400.css';
import '@fontsource/mulish/500.css';
import '@fontsource/mulish/600.css';
import '@fontsource/mulish/700.css';
import '@fontsource/mulish/800.css';
import '@fontsource/mulish/900.css';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

// Vuetify
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { aliases, md } from 'vuetify/iconsets/md'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import App from './App.vue'
import router from './router'


const vuetify = createVuetify({
	icons: {
		defaultSet: 'md',
		aliases,
		sets: {
			md,
		},
	},
	components,
	directives
})

const globalOptions = {
	mode: 'international'
};


createApp(App)
	.component("fa", FontAwesomeIcon)
	.use(store)
	.use(router)
	.use(VueTelInput,globalOptions)
	.use(vuetify)
	.use(PrimeVue,{ theme: {
		preset: Aura
	} })
	.mount('#app')
