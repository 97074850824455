<template>
  <v-dialog 
    v-model="showHallDialog"
    width="550"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeHallDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;font-size: 22px;">
            View Venue
          </h3>
          <div class="form-card">
            <Form
              ref="form"
              :validation-schema="schema"
              @submit="onSubmit"
            >
              <div class="form-row">
                <div class="hall-col">
                  <label
                    for="name"
                    style="margin-top: 12px;"
                  >Hall<span>*</span> </label>
                  <Field
                    v-model="name"
                    type="text"
                    name="name"
                    placeholder="Hall"
                  />
                  <ErrorMessage name="name" />
                </div>

                <div class="capacity-col">
                  <label
                    for="venueName"
                    style="margin-top: 12px;"
                  >Capacity<span>*</span></label>
                  <Field
                    v-model="capacity"
                    type="text"
                    name="capacity"
                    placeholder="Capacity"
                  />
                  <ErrorMessage name="capacity" />
                </div>

                <Field
                  v-model="venueId"
                  type="hidden"
                  name="venueId"
                  placeholder="Hall"
                />
                <ErrorMessage name="venueId" />

                <button
                  class="add-hall"
                  type="submit"
                >
                  <fa icon="plus" />
                </button>
              </div>
            </Form>

            <table>
              <thead>
                <tr>
                  <th>Halls List</th>
                  <th style="width:84px;">
                    Capacity
                  </th>
                  <th style="text-align: center;">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody v-if="hallList.length > 0">
                <tr
                  v-for="(item,index) in hallList"
                  :key="index"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.capacity }}</td>
                  <td>
                    <fa
                      icon="pencil"
                      class="pointer"
                      @click="editHall(index)"
                    />
                    <fa
                      icon="trash"
                      class="pointer"
                      @click="deleteHallConfirmation(index)"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td
                    colspan="3"
                    style="text-align: center;"
                  >
                    No Data
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="hall-actions">
              <button
                type="button"
                @click="closeHallDialog"
              >
                Close
              </button>
              <button
                type="button"
                @click="updateHalls"
              >
                Submit
              </button>
            </div>
            <span style="color: red;font-size: 14px ;">{{ hallErrorMsg }}</span>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>


  <!-- Edit Dialog -->
  <v-dialog 
    v-model="editHallDialog"
    width="550"
    persistent
  >
    <v-card class="add-event-dialog">
      <div
        class="close-icon"
        @click="closeEditDialog"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <v-container>
          <h3 style="margin-bottom:15px;font-size: 22px;">
            Edit Hall
          </h3>
          <div class="form-card">
            <Form
              ref="editForm"
              :validation-schema="editSchema"
              @submit="onUpdate"
            >
              <div class="form-row">
                <div class="hall-col">
                  <label
                    for="editname"
                    style="margin-top: 12px;"
                  >Hall</label>
                  <Field
                    v-model="editname"
                    type="text"
                    name="editname"
                    placeholder="Hall"
                  />
                  <ErrorMessage name="editname" />
                </div>

                <div class="capacity-col">
                  <label
                    for="editCapacity"
                    style="margin-top: 12px;"
                  >Capacity</label>
                  <Field
                    v-model="editCapacity"
                    type="text"
                    name="editCapacity"
                    placeholder="Capacity"
                  />
                  <ErrorMessage name="editCapacity" />
                </div>

                <button
                  class="add-hall"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </Form>
            <br>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- Edit Dialog -->

  <!-- Delete Dialog -->
  <v-dialog 
    v-model="deleteDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="deleteDialog = false"
      >
        <fa icon="close" />
      </div>
        
          
      <v-card-text>
        <h3> <fa icon="trash-can" /> Are you sure?</h3>
        Are you sure you want to delete this Hall? This action 
        cannot be undone.
      </v-card-text>
     
      <v-card-actions class="delete-action">
        <button @click="deleteDialog = false">
          Cancel
        </button>
        <button @click="deleteHall">
          Delete
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Delete Dialog -->

  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>

<script setup>
import {ref,reactive ,computed, inject, watch, onMounted} from 'vue'
import 'flatpickr/dist/flatpickr.css';
import { Form, Field, ErrorMessage} from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import countries from '../../countries.json';
import states from '../../states.json';
import { useStore } from 'vuex';

const emit = defineEmits(['submit'])


const store = useStore(); 
let showHallDialog =  ref(false);
let editHallDialog = ref(false);
let deleteDialog = ref(false);

let openHallDialog = inject('openHallDialog');
let venueId = inject('venueId');

// const formSubmitted = reactive({status:false});
// provide('formSubmitted',formSubmitted);

console.log("Inject",openHallDialog);

let file = ref(null);

const form  = ref();
const name = ref('');
const capacity = ref();


const editForm  = ref();
const editname = ref('');
const editCapacity = ref(0);


const hallIndex = ref(0);
const hallErrorMsg = ref('');


const snackbar= ref(false);
const text= ref('');
const timeout = ref(2000);
const serverName = ref('');
let hallList = reactive([
	// {name:'test1',capacity:230},
	// {name:'test2',capacity:530},
]);





const editHall = (index)=>{
	hallIndex.value = index;
	editname.value = hallList[index].name;
	editCapacity.value = hallList[index].capacity;
	editHallDialog.value = true;
}



const closeEditDialog = ()=>{
	editHallDialog.value = false;
}

const deleteHallConfirmation = (index)=>{
	hallIndex.value = index;
	deleteDialog.value = true;
}


const deleteHall = ()=>{
	hallList.splice(hallIndex.value,1)
	deleteDialog.value = false;
}


const closeHallDialog = () =>{
	// showHallDialog.value = false;
	store.state.venue.addHallPopup = false
}



showHallDialog = computed(() => {
	return store.state.venue.addHallPopup;
})




watch(showHallDialog, (newValue) => {
	console.log('showHallDialog',newValue)
})


watch(venueId, (newValue) => {
	console.log('venueId',newValue)
})





  
onMounted(() => {
  
	console.log("serverName",serverName.value);
	console.log("hallList",hallList);
	
	
})





const getCountry = ()=>{
	console.log("Loaded");
	
}
  










const closeDialog = ()=>{
	// openHallDialog.status =false;
	showHallDialog.value =false;
	
}





const schema = yup.object({
	name:yup.string().required('Please enter hall name'),
	capacity: yup
		.number()
		.typeError('Capacity must be a number')
		.integer('Only whole numbers are allowed')
		.required('Please enter capacity')
});

const editSchema = yup.object({
	editname:yup.string().optional(),
	editCapacity: yup.string().optional()
});



const onSubmit = (data) => {

	console.log("data",data);
	hallList.push(data);







	resetForm();
};

const onUpdate = (data) => {
	console.log("onUpdate",data);
	hallList[hallIndex.value].name = data.editname;
	 hallList[hallIndex.value].capacity = data.editCapacity;
	console.log("hallList",hallList);

	closeEditDialog()
}


const updateHalls = ()=>{

	if(hallList.length>0){
		console.log("hallList",hallList);


		axios
			.post(`${process.env.VUE_APP_SERVICE_URL}/hall`,
				hallList,
				{headers:{
					'Authorization': `Bearer ${store.state.authentication.token}`,
				}})
			.then((response) => {
				if(response.status === 200){
          
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Added successfully',
						color:'danger',
						timeout:2000});
					store.state.venue.addHallPopup = false;
					store.state.venue.tableRefresh = true;
				}
			})
			.catch((error)=>{
				if (error.response) {
					console.log(error.response);
					if(error.response.status === 400){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Hall already exists',
							color:'danger',
							timeout:2000});
					}
				}})

		
	}else{
		hallErrorMsg.value = 'Please add Hall';
	}
}



const resetForm = ()=>{
	form.value.resetForm();
}



</script>

<style scoped>
.v-container{padding: 0;}
.pointer{cursor: pointer;}
/* add even dialog form */
.add-event-dialog {position: relative!important;border-radius: 8px!important;}
.add-event-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}

 /* form design    */
.profile-upload{margin-bottom: 22px;}
.profile_image img{display: block;
    margin: 0 auto 15px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;}
.form-row{display: flex;align-items:flex-end;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input[type='file'] + span, input[type='text'] + span ,select + span ,textarea + span, .input-group + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex: 1; flex-direction: column;margin-right: 15px;margin-bottom: 12px;}
.form-col-half:last-child,.form-col-full:last-child{margin-right:0px;}
.hall-col{flex-basis: 65%;margin-right: 18px;}
.capacity-col{flex-basis: 20%;}

.form-col-half input[type='text'],.form-col-full input[type='text'],.form-col-half select,.form-col-full select,.hall-col input,.capacity-col input{border:1px solid #B2B2B2;border-radius: 5px;
    height: 39px;padding: 4px 13px;width: 100%;}
.form-col-half textarea ,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;padding: 4px 13px;}
 .form-col-half input[type='text']:focus,.form-col-full input[type='text']:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;margin-top: 34px;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}

/* date picker */
.input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
/* .input-group input{border:unset;outline: unset;} */
/* .flat-pickr{width:100%!important;}  */
/* .flatpickr-input{width: 30%;background-color: #007CBA;} */
:deep() .form-col-half  .date-form-control{
  width: 100%;height: 37px;padding: 12px;}

 :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
  
  .input-group-append {
  display: flex;
  align-items: center; /* Aligns buttons vertically */
  /* Add additional styles as needed */
}
.input-group-append button{margin-right: 7px;}
.input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
.radio-group{display: flex;}
.radio-input-wrap{display: flex;margin-right: 12px;}
.radio-input-wrap input{margin-right: 12px;}

select{border: 1px solid #B2B2B2;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    outline: unset;
    color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: auto 15%;
}
.add-hall{border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;
    height: 40px;}


    table {
   margin-top: 30px;   
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e4e3e3;
}

td, th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 12px;
  font-size: 14px;
    font-weight: 500;
}

thead th{background-color: #65a4c4;color:white;}
tr:nth-child(even) {
  background-color:#ecf2f6;
}
td:nth-child(2){text-align:right;}
td:nth-child(3){text-align: center;}
td .fa-trash{color:red;margin-left: 12px;}


.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
 .hall-actions{display: flex;
    justify-content: flex-end;
    margin: 18px 0px 18px 1px;
}   
 .hall-actions button{
  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 31px;
    margin-left: 29px;
    cursor: pointer;
    height: 35px;
    font-size: 15px;
 }

</style>
