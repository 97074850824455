<template>
  <div
    v-if="visible"
    class="dialog-overlay"
  >
    <div
      class="dialog"
      :style="{ backgroundColor: dialogBg}"
    >
      <div
        class="close-icon"
        @click="cancel"
      >
        <fa icon="close" />
      </div>
      
      <h3 class="dialog-title">
        <fa :icon="titleIcon" /> {{ title }}
      </h3>
      <div class="dialog-body">
        {{ body }}
      </div> 
          
      <div class="dialog-action">
        <button @click="cancel">
          {{ cancelText }}
        </button>
        <button
          :class="buttonBg"
          @click="confirm"
        >
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue'
const emit = defineEmits(['confirm', 'cancel']);

defineProps({
	visible: {
		type: Boolean,
		required: true,
		default:false
	},
  dialogBg: {
		type: String,
		required: false,
		default:'#fff'
	},
	title: {
		type: String,
		required: false,
		default:'Are you sure?'
	},
	titleIcon: {
		type: String,
		required: false,
		default:'trash-can'
	},
	body: {
		type: String,
		required: true,
		default:''
	},
	cancelText: {
		type: String,
		required: false,
		default:'Cancel'
	},
	buttonBg: {
		type: String,
		required: false,
		default:'info'
	},
	confirmText: {
		type: String,
		required: false,
		default:'Confirm'
	}

  
})


const confirm = ()=>{
	emit('confirm');
}
const cancel = () =>{
	emit('cancel');
}
</script>

<style scoped>
.dialog-title{display: flex;align-items: center;}
.dialog-title .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
 .dialog-body{margin-top: 12px;font-size: 16px;font-weight: 500;}   
.dialog-action{display: flex;justify-content: flex-end;}
.dialog-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.danger{background-color: #D92D20;color: white;border-color:#D92D20;}
.success{background-color: white;border: 1px solid #8BC34A;
    color: #8BC34A;}
.info{background-color: #007CBA;border: 1px solid #007CBA;
    color: white;}    
.dialog-action button{padding: 8px 13px;
    border-radius: 9px;
    border: 1px solid;}

    .dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}
.dialog {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 12px;
    width: 498px;
}
.close-icon{position: absolute;right: 19px;top: 15px;cursor: pointer;}


</style>