import { createStore } from 'vuex'
import router from '@/router';
import { isoStringDateformat, formatTime, isoDateformat} from '@/helper/index.js'
import moment from 'moment';
import { getTrackData } from '@/service/helper-service';

const authentication = {
	namespaced: true,
	state: () => ({
		token:localStorage.getItem('token') || '',
		name:localStorage.getItem('name') || '',
		id:localStorage.getItem('id') || '',
		phoneLogin:true,
	    phoneNumber:localStorage.getItem('phoneNumber') || ''}),
		
		
	mutations: { 
		setName(state,name){
			state.name = name;
			localStorage.setItem('name',name); 
		},
		setToken (state, token) {
			state.token = token;
			localStorage.setItem('token',token) },
		setID (state, id) {
			state.id = id;
			localStorage.setItem('id',id) },
		setDialCode (state, dialCode) {
			state.dialCode = dialCode;
			localStorage.setItem('dialCode',dialCode) },		
		setPhoneNumber (state, phoneNumber) {
			state.phoneNumber = phoneNumber;
			localStorage.setItem('phoneNumber',phoneNumber) },	
		clearToken (state) {
			state.token = '';
			localStorage.removeItem('token');},
		logout(state,payload) {
			localStorage.removeItem('token');
			localStorage.removeItem('name');
			localStorage.removeItem('dialcode');
			localStorage.removeItem('phoneNumber');
			state.token = '';
			state.name = '';
			state.dialcode = '';
			state.phoneNumber = '';
			setTimeout(()=>{
				router.push('/')
			},payload)
			
		},	
	},
	actions: {  
		// login(context, token) {
		// 	context.commit('setToken', token);},
		// logout(context) {
		// 	context.commit('clearToken');}
	},
	getters: {  }
}

const adminPanel = {
	state: () => ({
		drawer:null
	}),
	mutations: {  },
	actions: {  },
	getters: {  }
}

const members = {
	state: () => ({
		activeMembers:0,
		approvalPending:0,
		registrationPending:0,
		deactivatedMembers:0,
		rejectedMembers:0}),
	mutations: {  },
	actions: {  },
	getters: {  }
}

const event = {
	namespaced: true,
	state: () => ({
		activeEvent:0,
		eventHistory:0,
		allEvent:0,
		eventDates:[],
		events:[],
	    topics:[],
		isTopicUpdated:false,
		eventFormMode:'Create',
		scheduleFormMode:'Add',
		trackFormMode:'Add',
		selectedTrackDate:'',
		uniqueEventId:'',
		editTopicId:'',
		editScheduleData:'',
		venueId:'',
		showAddEventDialog:false,
		editEventData:'',
		editTrackData:'',
		eventId:'',
		isEventUpdated:false

	           }),
	mutations: { 
		addEvents(state,payload){
			state.events.push(payload);
		},
		addTopics(state,payload){
			state.topics.push(payload);
		},
		removeTopic(state,payload){
			let index =  state.topics.findIndex(topic => topic.id == payload.id);
			console.log('payload.index',index)
			if(index !=-1){
				state.topics.splice(index, 1);
			}
			
		},
		updateScheduleTime(state,payload){
			let index =  state.events.findIndex(event => event.id == payload.id);
			const time = moment(payload.time, 'HH:mm').add(payload.duration, 'minutes');
		   let newTime = time.format('HH:mm');
		   state.events[index].start = payload.duration;
		   state.events[index].start = `${isoDateformat(isoStringDateformat(payload.date +' '+ payload.time))} ${formatTime(isoStringDateformat(payload.date +' '+ payload.time))}` ,
		   state.events[index].end =  `${isoDateformat(isoStringDateformat(payload.date +' '+ newTime))} ${formatTime(isoStringDateformat(payload.date +' '+ newTime))}` 
		},
		setTrackDate(state,date){
			state.selectedTrackDate = date;
		}
	},
	actions: {  },
	getters: {  }
}

const speaker = {
	namespaced: true,
	state: () => ({
		isSpeakerUpdated:false,
		speakerFormMode:'Add',
		showAddSpeakerDialog:false,
		editSpeakerData :''
	}),
}

const venue = {
	state: () => ({
		venueEditMode:false,
		addHallPopup:false,
		tableRefresh:false,
		showAddVenueDialog:false,
		venueFormMode:'Add',
		editVenueData :''
	   }),
	mutations: {  },
	actions: {  },
	getters: {  }
}

const snackbar = {
	namespaced: true,
	state: () => ({
		show:false,
		timeout:2000,
		color:'success',
	    message:''}),
	mutations: { 
		showSnackbar(state,payload){
			state.show = payload.show;
			state.message = payload.message;
			state.color = payload.color;
			state.timeout = payload.timeout;
			
		}
	 },
	actions: {  },
	getters: {  }
}


const store =  createStore({
	
	modules:{
		authentication:authentication,
		adminPanel:adminPanel,
		members:members,
		event:event,
		speaker:speaker,
		venue:venue,
		snackbar:snackbar
	}
});




export default store;
