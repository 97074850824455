<template>
  <div style="position: fixed;margin:auto;display: block;width: 100%;">
    <AdminPanel>
      <v-container>
        <AddEvent />

      
        <div class="card-container">
          <div
            class="card"
            :class="{active:selectedInfoTab ==='All Event'}"
            @click="selectInfoCard('All Event')"
          >
            <div class="card-group">
              <fa icon="calendar-day" />
              <div class="count">
                {{ allEvent }}
              </div>
            </div>
            <div class="card-name">
              All Event
            </div>
          </div>
          <div
            class="card"
            :class="{active:selectedInfoTab ==='Active Events'}"
            @click="selectInfoCard('Active Events')"
          >
            <div class="card-group">
              <fa icon="calendar-day" />
              <div class="count">
                {{ activeEvents }}
              </div>
            </div>
            <div class="card-name">
              Active Event
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='Event History'}"
            @click="selectInfoCard('Event History')"
          >
            <div class="card-group">
              <fa icon="calendar-day" />
              <div class="count">
                {{ eventHistory }}
              </div>
            </div>
            <div class="card-name">
              Event History
            </div>
          </div>
        </div>

        <EventsList
          v-if="selectedInfoTab ==='All Event'"
          :type="'All Event'"
        />
        <EventsList
          v-if="selectedInfoTab ==='Active Events'" 
          :type="'Active Events'"
        /> 
        <EventsList
          v-if="selectedInfoTab ==='Event History'"
          :type="'Event History'"
        />
      </v-container>
    </AdminPanel>
  </div>
</template>

<script setup>
import {ref,reactive,provide,onMounted,computed } from 'vue'
import AdminPanel from '@/components/ui/AdminPanel.vue'
import AddEvent from '@/components/event_management/AddEvent.vue'

import EventsList from '@/components/event_management/EventsList.vue'
import axios from 'axios';
import { useStore } from 'vuex';

const searchText = ref('');
const selectedDate = ref('');
const selectedStatus = ref('');
const store = useStore(); 

const activeEvents = computed(() => {
	return store.state.event.activeEvent;
})

const eventHistory = computed(() => {
	return store.state.event.eventHistory;
})

const allEvent = computed(() => {
	return store.state.event.allEvent;
})



let selectedInfoTab = ref('All Event');
let openDialog = reactive({'status':false});

provide('openDialog',openDialog)



 
let selectInfoCard = (tabName) => {
	selectedInfoTab.value = tabName;
	searchText.value = '';
	selectedDate.value ='';
	selectedStatus.value = '';
  
}

onMounted(()=>{
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/eventCount`,{
			headers: {
				'Authorization': `Bearer ${store.state.authentication.token}`,
			}})
		.then((response) => {
			let eventCounts = response?.data?.data; 
        
			store.state.event.activeEvent = eventCounts?.activeEvents;
			store.state.event.eventHistory = eventCounts?.completedEvents; 
			store.state.event.allEvent = eventCounts?.allEvents;

		})
});


 
</script>

<style scoped>
.pointer{cursor: pointer;}
.card-container{display: flex;justify-content:flex-start;margin-top:-5px;}
   .card{border-radius: 6px;
    border: 1px solid #d3d2d2;
    background-color: #f7f6f6;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    flex-basis:200px;
    margin-left: 13px;
    cursor: pointer;
    
    }
    .card:nth-child(1){margin-left: unset;}
  .card:hover,.card.active{background-color: #007CBA;color:#fff;transition: ease-in-out .8s;}
  .card-group{display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;}
 
.card-name{font-weight: 600;margin-top: 12px;font-size: 14px;text-align: left;}
.card .svg-inline--fa{padding: 8px;border-radius: 50%;font-size: 21px;width: 20px; height: 20px;}
.card:nth-child(1) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(2) .svg-inline--fa{color:#F2881D;background-color: #EFE3D7;}
.card:nth-child(3) .svg-inline--fa{color: #3632D4;background-color: #cbcade;}
.card:nth-child(4) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.card:nth-child(5) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}




table {
  width: 100%;
  border-collapse: separate;
  border-spacing:0 15px;
  color:#000;
  font-weight: 600;
}

table  td, th {
  padding: 8px;
}
/* thead > tr {height: 70px;} */
thead > tr > th{border-top: 1px solid #ACACAC; text-align: left;padding: 27px 8px 12px 12px;}



thead > tr > th span{color:red;}
tbody > tr {
height: 85px;
position: relative;
border-radius: 5px;
border: 1px solid #ACACAC;
background-color: #F0FAFF;
box-shadow: 0px 10px 20px 0px rgba(207, 207, 207, 0.25);
}
tbody > tr:first-child{position: relative;}
tbody > tr:first-child::after{position:absolute;content: '';left: 0; background-color:#ACACAC;width: 100%;height: 1px;}
tbody > tr > td{text-align: left;border-top: 1px solid #ACACAC;border-bottom: 1px solid #ACACAC;padding: 14px;}
tbody > tr > td:first-child{border-left: 1px solid #ACACAC;border-radius: 5px 0 0 5px;}
tbody > tr > td:last-child{border-right: 1px solid #ACACAC;border-radius: 0 5px 5px 0;}
tbody > tr > td div{cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 5px;
    font-size: 14px;}

 tbody > tr > td div .fa-up-right-from-square{margin-right: 12px;}


</style>
